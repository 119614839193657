import { z } from 'zod'
import { baseSchema } from '../common/schema'
import { email, profileSchema } from '../profile/schema'
import { subscriptionTypeSchema } from '../subscription/schema'
import { stringValidator } from '@/utils/regex'
import { PaginationSchema } from '@/components/Pagination/schema'
import { UserDataType } from '../auth/schema'
import { roleSchema } from '../role/schema'
import { addressSchema } from '../address/schema'

// Use merge instead of extend, to avoid overriding
// reference:  https://zod.dev/?id=merge

// This is used for signup and customer create
// Created another schema because omiting/picking props will just make the code longer
// eslint-disable-next-line no-var

export const businessDetails = z.object({
    subscriptionTypeId: z.string(),
    stripeCoupon: z.string().optional(), // temporary
    businessId: z.string(),
    businessName: stringValidator('Business Name', { isRequired: true }),
    businessWebsite: stringValidator('Business Website', {
        fieldType: 'website',
        isRequired: true,
    }),
    status: z.string().optional().default('INA'), // status is not included in the form, but default value is INA, we can set this on the backend instead
})

export const customerBusinessesSchema = businessDetails
    .pick({ businessName: true, businessId: true })
    .merge(email)

export const customerBusinessList = z.array(customerBusinessesSchema)

export const businessSchema = z
    .object({
        subscriptionExpiryDate: z.string().optional(), // to ask
        subscriptionStripeId: z.string().optional(), // to ask
        subscriptionScheduleId: z.string().optional(),
        isChangePlan: z.string().optional(),
        isDowngrade: z.boolean().optional(),
        subscriptionType: subscriptionTypeSchema,
        contacted: z.boolean(),
    })
    .merge(businessDetails)
    .merge(baseSchema.partial())

export const profileBusinessSchema = profileSchema
    .omit({
        customerId: true,
        role: true,
        roleId: true,
    })
    .merge(
        z.object({
            business: businessSchema.partial(), // to ask sir Cy
        }),
    )

export const subscribersListSchema = z.object({
    content: z.array(profileBusinessSchema),
    meta: PaginationSchema,
})

export const countSchema = z.object({
    active: z.number(),
    billingPause: z.number(),
    terminated: z.number(),
})

export const ownerStatusCountSchema = z.object({
    content: countSchema,
})

export const updateBusinessStatusSchema = profileSchema
    .pick({
        profileId: true,
        businessId: true,
    })
    .extend({
        status: z.string(),
        isContacted: z.boolean().optional(),
    })

export const fullProfileSchema = profileSchema.extend({
    business: businessSchema,
    role: roleSchema,
    address: addressSchema,
})

export type ProfileBusinessResponseType = z.infer<typeof businessSchema> & {
    crews: z.infer<typeof profileSchema>[]
    customers: z.infer<typeof profileSchema>[]
}

export type ProfileSelfResponseType = z.infer<typeof profileBusinessSchema>
export type ProfileBusinessType = ProfileSelfResponseType
export type SubscriptionListType = z.infer<typeof subscribersListSchema>
export type StatusCount = z.infer<typeof countSchema>
export type OwnerStatusCount = z.infer<typeof ownerStatusCountSchema>
export type CustomerConnectedBusinessesType = z.infer<typeof customerBusinessesSchema>
export type CustomerConnectedBusinessListType = z.infer<typeof customerBusinessList>

export type BusinessStatusType = z.infer<typeof updateBusinessStatusSchema>

// To ask Dex, but this is for updating business profile //
export type BusinessProfileType = UserDataType
// ^ //
