import { AppRichTextBox } from '@/components/Common/AppRichTextbox'
import BulkSelectModal from '../../BulkSelect'
import { BiSolidContact } from 'react-icons/bi'
import { useEffect, useState } from 'react'
import { FaXmark } from 'react-icons/fa6'
import { useFormContext } from 'react-hook-form'
import { CreateGroupAndMessagingType } from '@/api/customer/schema'
import { customerContactContentAtom } from '@/store/customer'
import { useAtom } from 'jotai'
import { CustomerContactContentType } from '@/api/profile/schema'
import { MESSAGE_TYPE } from '@/constants'

interface MessageProps {
    messageType?: string
}

const MessageComposition = ({ messageType }: MessageProps) => {
    const [open, onClose] = useState<boolean>(false)
    const { getValues, setValue } = useFormContext<CreateGroupAndMessagingType>()

    const [selected, setSelected] = useAtom(customerContactContentAtom)

    const handleRemoveId = (id: string) => {
        setSelected((prev: CustomerContactContentType[]) =>
            prev.filter((sub: CustomerContactContentType) => sub.profileId !== id),
        )
    }

    useEffect(() => {
        const cleanup = () => {
            setSelected([])
        }
        return cleanup
    }, [])

    const handleEmailBodyChange = (val: string) => {
        const modifiedValue = val
            .replace(/<p><\/p>/g, '<br>')
            .replace(/\\/g, '')
            .replace(/<p\s*style\s*=\s*"text-align:\s*center"\s*><\/p>/g, '<br>')
            .replace(/<p\s*style\s*=\s*"text-align:\s*right"\s*><\/p>/g, '<br>')

        setValue('emailBody', modifiedValue)
    }

    const cleanHTML = (val: string) => {
        const content = val.replace(/^"(.+(?="$))"$/, '$1').replace(/\\"/g, '"')
        return content
    }

    return (
        <>
            <div className='flex flex-row space-x-16 items-center text-left'>
                <p className='text-nowrap'>
                    Add Contacts<span className='text-red-500'>*</span>
                </p>

                <div className='flex items-center space-x-2 w-full'>
                    <div className='w-full min-h-12 space-x-2 space-y-2 border border-gray-200 rounded-sm pl-2 pr-2 py-3 text-left'>
                        {selected &&
                            selected?.length > 0 &&
                            selected?.map((items) => (
                                <span
                                    key={items.name + '' + items.profileId}
                                    className='inline-flex space-x-2 items-center bg-gray-200 px-4 py-1 rounded-sm'
                                >
                                    <p>{items.name}</p>
                                    <button onClick={() => handleRemoveId(items.profileId)}>
                                        <FaXmark />
                                    </button>
                                </span>
                            ))}
                        {selected?.length === 0 && (
                            <button
                                onClick={() => onClose(true)}
                                className='text-gray-500 text-left w-full min-h-12 -space-y-2 -my-3'
                            >
                                Select your contacts
                            </button>
                        )}
                    </div>
                    <button onClick={() => onClose(true)}>
                        <BiSolidContact className='h-6 w-6' />
                    </button>
                </div>
            </div>
            {messageType === MESSAGE_TYPE.EMAIL && (
                <>
                    <div className='flex flex-row space-x-16 items-center text-left'>
                        <p className='text-nowrap'>
                            Email Subject<span className='text-red-500'>*</span>
                        </p>

                        <input
                            value={getValues().subject || ''}
                            name='subject'
                            onChange={(val) => setValue('subject', val.target.value)}
                            className='w-full border border-gray-200 rounded-sm pl-8 pr-2 py-3'
                        />
                    </div>

                    <div className='flex flex-row space-x-20 items-center text-left'>
                        <p className='text-nowrap align-text-top'>
                            Email Body<span className='text-red-500'>*</span>
                        </p>
                        <AppRichTextBox
                            value={cleanHTML(getValues().emailBody || '')}
                            name='emailBody'
                            onChange={(val) => handleEmailBodyChange(val)}
                            className='border border-gray-100 h-full'
                        />
                    </div>
                </>
            )}

            {messageType === MESSAGE_TYPE.SMS && (
                <div className='flex flex-row space-x-16 items-center text-left'>
                    <p className='text-nowrap'>
                        SMS Message<span className='text-red-500'>*</span>
                    </p>
                    <textarea
                        rows={8}
                        value={getValues().smsBody || ''}
                        name='smsBody'
                        onChange={(val) => setValue('smsBody', val.target.value)}
                        className='border border-gray-200 h-full w-full p-5'
                    />
                </div>
            )}
            {messageType === MESSAGE_TYPE.BOTH && (
                <>
                    <div className='flex flex-row space-x-16 items-center text-left'>
                        <p className='text-nowrap'>
                            Email Subject<span className='text-red-500'>*</span>
                        </p>
                        <input
                            name='subject'
                            value={getValues().subject || ''}
                            onChange={(val) => setValue('subject', val.target.value)}
                            className='w-full border border-gray-200 rounded-sm pl-8 pr-2 py-3'
                        />
                    </div>
                    <hr />
                    <div className='flex flex-row space-x-20 items-center text-left'>
                        <p className='text-nowrap align-text-top'>
                            Email Body<span className='text-red-500'>*</span>
                        </p>
                        <AppRichTextBox
                            value={cleanHTML(getValues().emailBody || '')}
                            name='emailBody'
                            onChange={(val) => handleEmailBodyChange(val)}
                            className='border border-gray-100 h-full'
                        />
                    </div>
                    <hr />
                    <div className='flex flex-row space-x-16 items-center text-left'>
                        <p className='text-nowrap'>
                            SMS Message<span className='text-red-500'>*</span>
                        </p>
                        <textarea
                            value={getValues().smsBody || ''}
                            rows={8}
                            name='smsBody'
                            onChange={(val) => setValue('smsBody', val.target.value)}
                            className='border border-gray-200 h-full w-full p-5'
                        />
                    </div>
                </>
            )}

            {messageType === MESSAGE_TYPE.DRAFT && (
                <>
                    <div className='flex flex-row space-x-16 items-center text-left'>
                        <p className='text-nowrap'>
                            Email Subject<span className='text-red-500'>*</span>
                        </p>

                        <input
                            value={getValues().subject || ''}
                            name='subject'
                            onChange={(val) => setValue('subject', val.target.value)}
                            className='w-full border border-gray-200 rounded-sm pl-8 pr-2 py-3'
                        />
                    </div>

                    <div className='flex flex-row space-x-20 items-center text-left'>
                        <p className='text-nowrap align-text-top'>
                            Email Body<span className='text-red-500'>*</span>
                        </p>
                        <AppRichTextBox
                            value={getValues().emailBody || ''}
                            name='emailBody'
                            onChange={(val) => handleEmailBodyChange(val)}
                            className='border border-gray-100 h-full'
                        />
                    </div>
                </>
            )}
            <BulkSelectModal onClose={onClose} isOpen={open} />
        </>
    )
}

export default MessageComposition
