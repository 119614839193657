import { signUpGalleryAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { SubscriptionType } from '@/api/subscription/schema'
import { getPricingById } from '@/api/subscription'
import ChangePlanButton from './ChangePlanButton'

const ActionButton = () => {
    const selected = useAtomValue(signUpGalleryAtom)
    const params = new URLSearchParams(window.location.search)
    const subscriptionTypeId = params.get('subscriptionTypeId')

    const { data: currentPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['Plan'],
        queryFn: () => getPricingById(subscriptionTypeId as string),
    })

    return (
        <div className='flex justify-end  w-full'>
            <div className='inline-flex gap-x-4'>
                {selected &&
                    selected?.length > 0 &&
                    selected
                        .sort((a, b) => {
                            const order = ['Starter', 'Standard', 'Growth Pro']
                            return order.indexOf(a.name as string) - order.indexOf(b.name as string)
                        })
                        ?.map((plan) => (
                            <div>
                                {currentPlan?.name === 'Starter'}
                                {currentPlan?.name === 'Starter' &&
                                    (currentPlan?.interval === 'month' ? (
                                        currentPlan?.name === plan.name &&
                                        plan.interval === 'month' ? (
                                            <ChangePlanButton
                                                plan={plan}
                                                changePlanType='Current Plan'
                                            />
                                        ) : (
                                            <ChangePlanButton
                                                plan={plan}
                                                changePlanType='Upgrade'
                                            />
                                        )
                                    ) : currentPlan?.name === plan.name &&
                                      plan.interval === 'year' ? (
                                        <ChangePlanButton
                                            plan={plan}
                                            changePlanType='Current Plan'
                                        />
                                    ) : plan.interval === 'month' ? (
                                        <ChangePlanButton plan={plan} changePlanType='Downgrade' />
                                    ) : (
                                        <ChangePlanButton plan={plan} changePlanType='Upgrade' />
                                    ))}

                                {currentPlan?.name === 'Standard' &&
                                    (currentPlan?.interval === 'month' ? (
                                        'Standard' === plan.name && plan.interval === 'month' ? (
                                            <ChangePlanButton
                                                plan={plan}
                                                changePlanType='Current Plan'
                                            />
                                        ) : 'Starter' === plan.name && plan.interval === 'month' ? (
                                            <ChangePlanButton
                                                plan={plan}
                                                changePlanType='Downgrade'
                                            />
                                        ) : (
                                            <ChangePlanButton
                                                plan={plan}
                                                changePlanType='Upgrade'
                                            />
                                        )
                                    ) : currentPlan?.name === plan.name &&
                                      plan.interval === 'year' ? (
                                        <ChangePlanButton
                                            plan={plan}
                                            changePlanType='Current Plan'
                                        />
                                    ) : 'Pro' === plan.name && plan.interval === 'year' ? (
                                        <ChangePlanButton plan={plan} changePlanType='Upgrade' />
                                    ) : (
                                        <ChangePlanButton plan={plan} changePlanType='Downgrade' />
                                    ))}

                                {currentPlan?.name === 'Growth Pro' &&
                                    (currentPlan?.interval === 'year' ? (
                                        currentPlan?.name === plan.name &&
                                        plan.interval === 'year' ? (
                                            <ChangePlanButton
                                                plan={plan}
                                                changePlanType='Current Plan'
                                            />
                                        ) : (
                                            <ChangePlanButton
                                                plan={plan}
                                                changePlanType='Downgrade'
                                            />
                                        )
                                    ) : currentPlan?.name === plan.name &&
                                      plan.interval === 'month' ? (
                                        <ChangePlanButton
                                            plan={plan}
                                            changePlanType='Current Plan'
                                        />
                                    ) : plan.interval === 'month' ? (
                                        <ChangePlanButton plan={plan} changePlanType='Downgrade' />
                                    ) : (
                                        <ChangePlanButton plan={plan} changePlanType='Upgrade' />
                                    ))}
                            </div>
                        ))}
            </div>
        </div>
    )
}

export default ActionButton
