import { useQuery } from '@tanstack/react-query'
import { getJobById } from '@/api/job'
import Spinner from '@/components/Spinner'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import emptyPhotosPlaceholder from '@/assets/private/empty-photos.png'
import { useParams } from 'react-router-dom'
import { IoAdd } from 'react-icons/io5'
import JobPhotoCarousel from './PhotoGallery'
import { useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import UploadOptions from './UploadOptions'
import { useTranslation } from 'react-i18next'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/auth'
import { FEATURE_RESTRICTIONS } from '@/constants'
import CrewFeatureRestrictionModal from '@/components/CrewFeatureRestrictionModal'

const CrewJobPhotos = () => {
    const { t } = useTranslation(['view-job'])
    const { jobId } = useParams()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedImageId, setSelectedImageId] = useState<string>('')
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const user = useAtomValue(userAtom)

    const { data: isFeatureAvailable, isPending: featureLoading } = useQuery<boolean>({
        queryKey: ['crewJobFeatureRestriction', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.jobLogImages,
            ),
    })

    const { data: images, isLoading } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
        select: (res) => res?.jobImages || [],
    })

    if (isLoading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )
    }

    if (isFeatureAvailable && selectedImageId && images && images.length > 0) {
        return (
            <div className='flex flex-col justify-evenly bg-zentive-gray-medium h-screen w-full min-w-full max-w-full'>
                <div className='absolute flex flex-row items-center gap-x-4 ml-6 mt-4 top-0'>
                    <BsArrowLeft
                        className='h-5 w-5 md:h-10 md:w-10 cursor-pointer text-white'
                        onClick={() => setSelectedImageId('')}
                    />
                    <span className='font-semibold text-sm md:text-2xl text-white'>Photos</span>
                </div>
                <div className='bg-zentive-black py-10'>
                    <JobPhotoCarousel images={images} selectedId={selectedImageId} />
                </div>
            </div>
        )
    }

    return (
        <div className='flex flex-col justify-between items-center'>
            <BackButtonTopBar
                title={t('Photos')}
                redirectTo={`/crew/job/${jobId}`}
                showUpgradeBadge={!isFeatureAvailable}
                isLoading={featureLoading}
            />
            <div className='grid grid-cols-3 gap-1 p-6'>
                <button
                    className='flex flex-col justify-center cursor-pointer rounded-md items-center bg-zentive-green-light gap-4 p-9 border border-dotted border-zentive-green-dark'
                    onClick={() => {
                        setRestrictionModal(!isFeatureAvailable)
                        setIsOpen(isFeatureAvailable as boolean)
                    }}
                    type='button'
                    disabled={featureLoading}
                >
                    <IoAdd className='h-6 w-6 text-zentive-green-dark' />
                </button>

                {isFeatureAvailable &&
                    images?.map((image, index) => (
                        <img
                            src={image.jobImageUrl}
                            onClick={() => setSelectedImageId(image.jobImageId)}
                            key={index}
                            alt='job image'
                            className='w-24 h-24 object-cover rounded-md cursor-pointer'
                        />
                    ))}
            </div>

            {!images ||
                (!isFeatureAvailable && (
                    <div className='px-4 mx-4 my-10'>
                        <div className='flex justify-center rounded-md bg-white'>
                            <img
                                src={emptyPhotosPlaceholder}
                                alt='empty-table-placeholder'
                                className='h-44'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-white font-semibold mt-4'>
                            {t('No Photos Found')}
                        </p>
                        <p className='pb-4 text-center text-white'>
                            {t('Your photo gallery is currently empty.')}
                        </p>
                    </div>
                ))}

            <UploadOptions isOpen={isOpen} setIsOpen={setIsOpen} />
            <CrewFeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </div>
    )
}

export default CrewJobPhotos
