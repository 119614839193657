import { NotificationResponseType } from '@/api/auth/schema'
import { cn } from '@/utils/helper'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { notificationIcons } from '@/constants'

export const NotificationItems = ({
    notification,
    handleClick,
}: {
    notification: NotificationResponseType
    handleClick: (id: string) => void
}) => {
    dayjs.extend(relativeTime)

    return (
        <button className='w-full' onClick={() => handleClick(notification.id)}>
            <div
                className={cn(
                    'flex flex-row text-start gap-5 px-5 pt-2 pb-2 h-[6rem] my-1 hover:bg-zentive-gray-bg',
                    notification.read ? ' ' : 'bg-zentive-green-light',
                )}
            >
                <div className='flex items-center justify-center py-4'>
                    <div className='bg-zentive-green-dark rounded-full w-12 h-12 p-3'>
                        <img
                            src={notificationIcons[notification?.content?.category || 'default']}
                            className='h-6 w-6'
                            alt='Notification Icon'
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <p className='font-bold text-sm text-zentive-green-dark/80'>
                        {notification.content.title}
                    </p>
                    <p className='text-sm text-zentive-black'>{notification.content.message}</p>
                    <p className='text-xs text-zentive-gray-medium'>
                        {dayjs().from(dayjs(notification.createdAt), true)} ago
                    </p>
                </div>
            </div>
        </button>
    )
}

