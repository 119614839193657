import { InvoiceListType } from '@/api/customer/schema'
import { COMPLETE_DATE_TIME_FORMAT_WITH_MERIDIEM } from '@/constants'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import { cn } from '@/utils/helper'
import { formatDateToUserTimezone } from '@/utils/time'
import { useAtomValue } from 'jotai'

interface InvoiceListTypeProps {
    invoiceList: InvoiceListType | undefined
}

const PaymentComponent = (invoiceList: InvoiceListType) => {
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const formattedPaidDate = formatDateToUserTimezone(
        invoiceList?.invoiceInfo.paidDate as string,
        timezone.timeZoneId,
        COMPLETE_DATE_TIME_FORMAT_WITH_MERIDIEM,
        'General Format',
    )

    if (invoiceList?.invoiceInfo.status === 'paid') {
        return (
            <>
                <div className='border-solid border-[1px] col-span-4 h-12 text-zentive-gray-medium pl-3 text-base flex items-center font-semibold'>
                    Payments
                </div>
                <div className='border-solid border-[1px] col-span-4 h-12 pl-3 text-base pt-3'>
                    ${(invoiceList?.invoiceInfo.amount! / 100)?.toLocaleString()} was paid on{' '}
                    {formattedPaidDate} by{' '}
                    {invoiceList?.customerBillingInfo.cardBrand?.toUpperCase()} ending{' '}
                    {invoiceList?.customerBillingInfo.lastFourNo}
                </div>
            </>
        )
    }
    return null
}

const InvoiceDialogContent = ({ invoiceList }: InvoiceListTypeProps) => {
    const payment = PaymentComponent(invoiceList as InvoiceListType)

    return (
        <div id='main' className='my-20 grid grid-cols-4 rounded-sm justify-evenly'>
            <div className='border-solid border-2 h-12 text-zentive-gray-medium pl-3 text-base flex items-center font-semibold'>
                Invoice Number
            </div>
            <div className='border-solid border-2 h-12 text-zentive-gray-medium pl-3 text-base flex items-center font-semibold'>
                Invoice Date
            </div>
            <div className='border-solid border-2 h-12 text-zentive-gray-medium pl-3 text-base flex items-center font-semibold'>
                Bill Amount
            </div>
            <div className='border-solid border-2 h-12 text-zentive-gray-medium pl-3 text-base flex items-center font-semibold'>
                Status
            </div>
            <div className='border-solid border-2 h-20 pl-3 text-base pt-3'>
                {invoiceList?.invoiceInfo.number}
            </div>
            <div className='border-solid border-2 h-20 pl-3 text-base pt-3'>
                {invoiceList?.invoiceInfo.created}
            </div>
            <div className='border-solid border-2 h-20 pl-3 text-base pt-3'>
                ${(invoiceList?.invoiceInfo.amount! / 100)?.toLocaleString()}
            </div>
            <div
                className={cn(
                    'border-solid border-2 h-20 pl-3 text-base pt-3 capitalize',
                    invoiceList?.invoiceInfo.status === 'paid'
                        ? 'text-zentive-green-dark '
                        : 'text-zentive-red-dark',
                )}
            >
                {invoiceList?.invoiceInfo.status}
            </div>
            <div className='border-solid border-[1px] col-span-4 h-12 flex items-center'>
                <div className='grid grid-cols-2 justify-items-start w-full '>
                    <div className='flex flex-col '>
                        <p className='font-bold text-zentive-gray-medium pl-3 text-base  font-semibold'>
                            Description
                        </p>
                    </div>
                    <div className='flex flex-col w-full  items-end'>
                        <div className='justify-items-start'>
                            <p className='font-bold text-left text-zentive-gray-medium pr-3 text-base  font-semibold'>
                                Amount
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='border-solid border-[1px] col-span-4 p-3'>
                <div className='grid grid-cols-2 justify-items-start w-full'>
                    <div className='flex flex-col'>
                        <p className=''>Service Purchased</p>
                    </div>
                    <div className='flex flex-col w-full min-h-20  items-end'>
                        <div className='justify-items-end'>
                            <p className=' text-right'>
                                ${(invoiceList?.invoiceInfo.amount! / 100)?.toLocaleString()}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {payment}
        </div>
    )
}

export default InvoiceDialogContent
