import {
    BulkProfileUpdateStatusType,
    CustomerProfileListType,
    FullProfileType,
} from '@/api/profile/schema'
import { Card, CardContent } from '@/components/Card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import Tooltip from '@/components/Tooltip'
import { customerProfileAtom } from '@/store/customer'
import { cn, formatPhoneNumber, formatRecordNumber } from '@/utils/helper'
import { useAtom } from 'jotai'
import { E164Number } from 'libphonenumber-js/core'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom'

import { Button } from '@/components/Button'

import { USER_STATUS } from '@/constants'
import DeleteModal from '../DeleteModal'
import { IoPersonRemoveSharp, IoPersonAddSharp } from 'react-icons/io5'

const tableHeader = [
    {
        name: 'Account Number',
    },
    {
        name: 'Name',
    },
    {
        name: 'Email Address',
    },
    {
        name: 'Address',
    },
    {
        name: 'Contact Number',
    },
    {
        name: 'Action',
    },
]
interface CustomerTableProps {
    customers: CustomerProfileListType | null
    setProfilesToUpdate: React.Dispatch<
        React.SetStateAction<{
            profileIds: string[]
            status?: string | undefined
            businessId?: string
        }>
    >
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    isCheckAll: boolean
    profilesToUpdate: BulkProfileUpdateStatusType
    businessId: string
    user: FullProfileType
    selected: string
}

const CustomerTable: React.FC<CustomerTableProps> = ({
    customers,
    setProfilesToUpdate,
    profilesToUpdate,
    user,
    selected,
}) => {
    const [customerProfileData, setCustomerProfileData] = useAtom(customerProfileAtom)

    const [showModal, setShowModal] = useState(false)

    const xl_vw_already = useMediaQuery({ maxWidth: 1370 })
    const xxl_vw_already = useMediaQuery({ maxWidth: 1620 })

    useEffect(() => {
        setCustomerProfileData(null)
    }, [customerProfileData])

    return (
        <Card className={cn(xxl_vw_already ? 'bg-white overflow-x-auto green-scrollbar' : '')}>
            <CardContent className='mt-4'>
                <Table>
                    <TableHeader style={{ marginBottom: '10px' }}>
                        <TableRow>
                            {tableHeader?.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <div className='inline-flex whitespace-nowrap'>
                                        {header.name}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {customers?.content?.map((data, index) => (
                            <TableRow key={index} className='whitespace-nowrap'>
                                <TableCell className='text-start flex items-center text-zentive-black text-base mt-[8px]'>
                                    <NavLink
                                        to={`/customers/customer-list/${data.profileId}`}
                                        onClick={() => setCustomerProfileData(data)}
                                    >
                                        {formatRecordNumber(data.accountNumber ?? 0)}
                                    </NavLink>
                                </TableCell>
                                <TableCell className='text-start text-zentive-black text-base'>
                                    <NavLink
                                        to={`/customers/customer-list/${data.profileId}`}
                                        onClick={() => setCustomerProfileData(data)}
                                    >
                                        {data.firstName + '  ' + data.lastName}
                                    </NavLink>
                                </TableCell>
                                <TableCell
                                    className={cn('text-start text-zentive-black text-base')}
                                >
                                    <Tooltip position='top' content={data.email}>
                                        <NavLink
                                            to={`/customers/customer-list/${data.profileId}`}
                                            onClick={() => setCustomerProfileData(data)}
                                        >
                                            <p
                                                className={cn(
                                                    xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                                    xl_vw_already && 'w-full',
                                                )}
                                            >
                                                {data.email}
                                            </p>
                                        </NavLink>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    className={cn('text-start text-zentive-black text-base')}
                                >
                                    <Tooltip position='top' content={data.address.streetAddress}>
                                        <NavLink to={`/customers/customer-list/${data.profileId}`}>
                                            <p
                                                className={cn(
                                                    xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                                    xl_vw_already && 'w-full',
                                                )}
                                            >
                                                {data.address.streetAddress}
                                            </p>
                                        </NavLink>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='text-start text-zentive-black text-base'>
                                    <NavLink to={`/customers/customer-list/${data.profileId}`}>
                                        {formatPhoneNumber(data.phoneNumber as E164Number)}
                                    </NavLink>
                                </TableCell>
                                <TableCell className='w-auto'>
                                        <Tooltip content='Change User Status' position='top'>
                                            {/* <Switch
                                                className='mt-[.20rem] flex flex-wrap items-center'
                                                onClick={() => {
                                                    setProfilesToUpdate((prevState) => ({
                                                        ...prevState,
                                                        profileIds: [data.profileId],
                                                    }))
                                                    setShowModal(true)
                                                }}
                                                checked={
                                                    selected == USER_STATUS.ACTIVATED ? true : false
                                                }
                                            /> */}
                                            {
                                                selected == USER_STATUS.ACTIVATED ?
                                                <Button
                                                    variant='ghost'
                                                    onClick={() => {
                                                        setProfilesToUpdate((prevState) => ({
                                                            ...prevState,
                                                            profileIds: [data.profileId],
                                                        }))
                                                        setShowModal(true)
                                                    }}
                                                >
                                                    <IoPersonRemoveSharp className='w-[1.5em] h-[1.5em] text-zentive-red-500' />
                                                </Button>
                                                
                                                :
                                                <Button
                                                    variant='ghost'
                                                    onClick={() => {
                                                        setProfilesToUpdate((prevState) => ({
                                                            ...prevState,
                                                            profileIds: [data.profileId],
                                                        }))
                                                        setShowModal(true)
                                                    }}
                                                >
                                                    <IoPersonAddSharp className='w-[1.5em] h-[1.5em] text-zentive-green-dark' />
                                                </Button>
                                            }
                                        </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <DeleteModal
                    profilesToUpdate={profilesToUpdate}
                    onSuccessRestore={() => {
                        setProfilesToUpdate({
                            profileIds: [],
                            status:
                                selected == USER_STATUS.ACTIVATED
                                    ? USER_STATUS.INACTIVE
                                    : USER_STATUS.ACTIVATED,
                            businessId: user?.businessId,
                        })
                    }}
                    open={showModal}
                    setOpen={setShowModal}
                    selected={selected}
                />
            </CardContent>
        </Card>
    )
}
export default CustomerTable
