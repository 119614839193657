import { ProfileBusinessType, BusinessStatusType as ProfileStatusType } from '@/api/business/schema'
import {
    BillingInfoType,
    CustomerContactContentType,
    CustomerContactType,
    CustomerProfileListType,
    CustomerProfileType,
    ParamsFilterType,
    ProfileType,
} from '@/api/profile/schema'
import { atom } from 'jotai'
import { USER_STATUS } from '@/constants'
import { UserDataType } from '@/api/auth/schema'
import { IFile } from '@/components/Common/ImageUploader'
import { ProductServiceType, WorkServiceHistoryType } from '@/api/service/schema'
import { MessageListType, MessagesInboxListType } from '@/api/customer/schema'
import { EmployeeType } from '@/api/employee/schema'

export const profileStatusAtom = atom<ProfileStatusType | null>(null)
export const customerProfileAtom = atom<UserDataType | ProfileBusinessType | EmployeeType | ProfileType | null>(
    null,
)
export const updateCustomerProfileAtom = atom<CustomerProfileType | null>(null)
export const lastJobDateAtom = atom<string>('')
export const customerImgAtom = atom<IFile[]>([])
export const selectedStatus = atom<string>(USER_STATUS.ACTIVATED)
export const selectedCustomerMessageStatusAtom = atom<string>('both')
export const selectedCrewTabStatusAtom = atom<string>('ACT')
export const customerMessagesAtom = atom<MessageListType[] | null>([])
export const customerMessageAtom = atom<MessageListType | null>(null)
export const customemrMessageListAtom = atom<MessagesInboxListType | null>(null)
export const messagesListAtom = atom<MessageListType[]>([])
export const allCustomersAtom = atom<CustomerProfileType[]>([])

export const manageCustomersAtom = atom<CustomerProfileListType | null>(null)

export const serviceHistoryAtom = atom<WorkServiceHistoryType | null>(null)
export const billingInfoAtom = atom<BillingInfoType | null>(null)

export const isAddressSelectedAtom = atom<boolean>(false)

isAddressSelectedAtom.onMount = (setAtom) => {
    return () => setAtom(false)
}

export const filterCustomer = atom<ParamsFilterType | null>(null)
export const customerContactAtom = atom<CustomerContactType | null>(null)
export const customerContactContentAtom = atom<CustomerContactContentType[]>([])
export const businessServiceTypeAtom = atom<ProductServiceType[] | null>([])
export const serviceProductFilter = atom<string>('')
export const serviceProductApprovedFilter = atom<string>('')
export const messagingSortByAtom = atom<string>('')
export const messagingSortOrderAtom = atom<string>('')
export const workingHistorySortByAtom = atom<string>('')
export const workingHistorySortOrderAtom = atom<string>('')
