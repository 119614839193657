import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { ProductServiceType } from '@/api/service/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Button } from '@/components/Button'
import { useToast } from '@/hooks/useToast'
import { saveService } from '@/api/service'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { FullProfileType } from '@/api/profile/schema'
import { PRODUCT_SERVICE_TYPES } from '@/constants'
import { productServiceSchema } from '@/api/quoting/schema'
import {
    AlertDialogContent,
    AlertDialog,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Input } from '@/components/Input'
import { handleNumberInputChange } from '@/utils/eventHandler'
import { Textarea } from '@/components/TextArea'

interface AddProductServiceProps {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

const AddProductService = ({ isOpen, setIsOpen }: AddProductServiceProps) => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const { toast } = useToast()

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const methods = useForm<ProductServiceType>({
        defaultValues: {
            businessId: user?.businessId,
            cost: 0.0,
            description: '',
            isTaxExempt: false,
            markup: 0.0,
            name: undefined,
            type: PRODUCT_SERVICE_TYPES[1].toLowerCase(),
            unitPrice: 0.0,
        },
        mode: 'onChange',
        resolver: zodResolver(productServiceSchema),
    })

    const {
        handleSubmit,
        formState: { isValid },
        control,
        watch,
        setValue,
    } = methods

    const [markup, unitPrice] = watch(['markup', 'unitPrice'])

    const { mutate: saveServiceMu } = useMutation<
        ProductServiceType,
        AxiosError,
        ProductServiceType
    >({
        mutationKey: ['SaveService'],
        mutationFn: saveService,
        onSuccess: () => {
            toast({
                description: `${
                    methods.getValues('type').charAt(0).toUpperCase() +
                    methods.getValues('type').slice(1)
                } Saved`,
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['RequestServiceType'] })
            setIsOpen(false)
            setIsSubmitting(true)
        },
        onError: (err) => {
            console.error(err)
            toast({
                description: 'Product/Service already exists',
                variant: 'destructive',
            })
            setIsOpen(false)
        },
    })

    const onMarkupChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
        const markup = handleNumberInputChange(evt)
        setValue('markup', markup, {
            shouldValidate: true,
        })
    }

    const onTypeChange: ChangeEventHandler<HTMLSelectElement> = (evt) => {
        setValue('type', evt.target.value, {
            shouldValidate: true,
        })
    }

    const onUnitPriceChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
        const unitPrice = handleNumberInputChange(evt)
        setValue('unitPrice', unitPrice, {
            shouldValidate: true,
        })
    }

    const onSubmit = (data: ProductServiceType) => saveServiceMu(data)

    const onCancel = () => {
        setIsSubmitting(false)
        setIsOpen(false)
        setValue('name', undefined)
        setValue('description', '')
        setValue('type', PRODUCT_SERVICE_TYPES[1].toLowerCase())
        setValue('markup', undefined)
        setValue('cost', 0.0)
        setValue('unitPrice', 0.0)
    }

    useEffect(() => {
        if (!unitPrice && !markup) return

        const cost = Number((unitPrice + (unitPrice * (markup ?? 0)) / 100).toFixed(2))
        setValue('cost', cost, {
            shouldValidate: true,
        })
    }, [markup, unitPrice])

    return (
        <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
            <AlertDialogContent className='bg-white rounded-[15px] p-0'>
                <AlertDialogTitle className='text-left font-semibold text-lg text-zentive-black p-10 pb-0'>
                    <h1 className='text-2xl font-semibold text-left mb-2'>
                        Add Products Or Services
                    </h1>
                </AlertDialogTitle>

                <div className='flex py-2 justify-center px-14'>
                    <Form {...methods}>
                        <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-col'>
                                <FormField
                                    control={control}
                                    name='type'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <select
                                                    className='w-[560px] max-w-[560px] hover:bg-none items-center font-sans text-base pl-3 h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900 rounded'
                                                    {...field}
                                                    onChange={onTypeChange}
                                                >
                                                    {PRODUCT_SERVICE_TYPES?.map((type) => (
                                                        <option
                                                            id={type}
                                                            value={type.toLowerCase()}
                                                            selected={
                                                                field.value === type.toLowerCase()
                                                            }
                                                        >
                                                            {type}
                                                        </option>
                                                    ))}
                                                </select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='mt-4'>
                                <FormField
                                    control={control}
                                    name='name'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='w-[560px] max-w-[560px] items-center font-sans text-base h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900'
                                                    placeholder='Name'
                                                    type='text'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <div className='mt-4'>
                                <FormField
                                    control={control}
                                    name='description'
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Textarea
                                                    className='py-4 w-[560px] max-w-[560px] min-h-[7.8rem] items-center font-sans text-base border border-zentive-gray-light disabled:bg-gray-100 text-gray-900'
                                                    placeholder='Description'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <div className='flex flex-row mt-4 w-full gap-1'>
                                <FormField
                                    control={control}
                                    name='cost'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                                    placeholder='Cost $'
                                                    type='text'
                                                    disabled
                                                    {...field}
                                                    value={
                                                        field.value != 0
                                                            ? typeof field.value === 'number' &&
                                                              !isNaN(field.value)
                                                                ? `$${field.value.toLocaleString(
                                                                      undefined,
                                                                      {
                                                                          maximumFractionDigits: 2,
                                                                          minimumFractionDigits: 2,
                                                                      },
                                                                  )}`
                                                                : ''
                                                            : ''
                                                    }
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    name='markup'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                                    placeholder='Markup % (Optional)'
                                                    type='number'
                                                    {...field}
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === '-' ||
                                                            e.key === '+' ||
                                                            e.key === 'e' ||
                                                            e.key === 'E'
                                                        ) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                    onChange={onMarkupChange}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    name='unitPrice'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                                    placeholder='Unit Price $'
                                                    type='number'
                                                    {...field}
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === '-' ||
                                                            e.key === '+' ||
                                                            e.key === 'e' ||
                                                            e.key === 'E'
                                                        ) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                    onChange={onUnitPriceChange}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </form>
                    </Form>
                </div>
                <AlertDialogFooter className='py-5 px-10 w-full rounded-b-md justify-center items-end bg-zentive-gray-light'>
                    <div className='flex flex-row w-[35%] gap-x-4'>
                        <Button
                            className='w-[95px] h-[43px] border border-zentive-green-dark text-zentive-green-dark hover:text-white font-semibold bg-white rounded-[5px] hover:bg-zentive-green-dark'
                            type='button'
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            className='w-[95px] h-[43px] text-white font-semibold bg-zentive-green-dark  rounded-[5px] hover:bg-zentive-green-medium'
                            type='submit'
                            disabled={!isValid || isSubmitting}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </Button>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default AddProductService
