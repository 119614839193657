import { CardContent, CardFooter, CardTitle } from '@/components/Card'
import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import GalleryTable from '@/pages/public/SignUp/SignUpGallery/Table'
import { SIGN_UP_GALLERY, SubscriptionInterval } from '@/assets/static/signupGallery'
import ActionButton from '../../ActionButton'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { checkCustomerPaymentMethod, getPricing } from '@/api/subscription'
import { useAtomValue, useSetAtom } from 'jotai'
import SetupPaymentModal from '@/components/SetupPaymentModal'
import { isFreeTrialEndedAtom } from '@/store/owner'
import { isSetupPaymentOpenAtom } from '@/store/subscription'
import { Button } from '@/components/Button'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const SubscriptionPlans = () => {
    const [interval, setInterval] = useState<SubscriptionInterval>('month')

    const user = useViewedAsUser()
    const { customerId = '' } = user

    const isFreeTrialEnded = useAtomValue(isFreeTrialEndedAtom)

    const setSetupPaymentDone = useSetAtom(isSetupPaymentOpenAtom)

    const { data } = useQuery({
        queryKey: ['pricesByInterval', interval],
        queryFn: () => getPricing(interval),
    })

    const { data: hasPayment, isLoading: hasPaymentLoading } = useQuery({
        queryKey: ['hasPayment', customerId],
        queryFn: () => checkCustomerPaymentMethod(customerId),
    })

    if (data && data?.length > 0 && !hasPaymentLoading) {
        return (
            <>
                <SetupPaymentModal />
                <section>
                    {!isFreeTrialEnded && (
                        <p className='mt-9 text-zentive-gray-medium font-bold '>
                            Subscription Plans
                        </p>
                    )}
                    <div className='bg-white pt-2 w-full rounded-md mt-3 shadow-md'>
                        {isFreeTrialEnded && (
                            <CardTitle className='text-[#3B671A] text-center text-[24px] mt-5 pb-2'>
                                Pricing
                            </CardTitle>
                        )}
                        <div className={`${isFreeTrialEnded ? '' : 'my-10'}`}>
                            <CardContent className='p-0 shadow-md'>
                                <div className='flex justify-center mt-4 mb-14 '>
                                    <Tabs
                                        defaultValue='month'
                                        onValueChange={(val) =>
                                            setInterval(val as SubscriptionInterval)
                                        }
                                        className='w-[400px] '
                                    >
                                        <TabsList className='grid w-full grid-cols-2 rounded-full bg-[#0C3D3C]'>
                                            <TabsTrigger
                                                value='month'
                                                className=' data-[state=active]:bg-transparent data-[state=active]:rounded-full data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:rounded-full data-[state=inactive]:text-gray-900'
                                            >
                                                Monthly
                                            </TabsTrigger>
                                            <TabsTrigger
                                                value='year'
                                                className=' data-[state=active]:bg-transparent data-[state=active]:rounded-full data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:rounded-full data-[state=inactive]:text-gray-900'
                                            >
                                                Annually
                                            </TabsTrigger>
                                        </TabsList>
                                    </Tabs>
                                </div>
                                {
                                    <GalleryTable
                                        tableData={SIGN_UP_GALLERY[interval]}
                                        interval={interval}
                                    />
                                }
                            </CardContent>
                            <CardFooter
                                className='
                                    bg-zentive-gray-light p-0 pb-4 
                                    xl:pr-4 
                                    2xl:pr-12
                                    w2xl:pr-[60px]
                                    3xl:pr-24
                                    4xl:pr-52
                                    shadow-md rounded-sm'
                            >
                                {hasPayment ? (
                                    <ActionButton selected={data} interval={interval} />
                                ) : (
                                    <div className='flex w-full items-center justify-center pt-5'>
                                        <Button
                                            type='button'
                                            onClick={() => setSetupPaymentDone(true)}
                                        >
                                            Setup Payment Details
                                        </Button>
                                    </div>
                                )}
                            </CardFooter>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default SubscriptionPlans
