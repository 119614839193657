import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import * as React from 'react'

interface IProps {
    isOpen: boolean
    onClose: () => void
    handleDiscard: () => void
    onSubmitDraft: () => void
}

const DiscardModal: React.FC<IProps> = (props) => {
    return (
        <Modal
            isOpen={props.isOpen}
            isHideCloseButton
            onClose={() => {
                props.onClose()
            }}
            title='Save Changes?'
            titleClassName='text-zentive-blue-dark ml-5 mt-5'
        >
            <div className='flex flex-col gap-5  text-left text-sm text-gray-500 mx-auto '>
                <div className='max-w-[446px] min-w-[446px] ml-12 mx-auto flex flex-col gap-5 mb-5'>
                    <p>Are you sure you want to navigate away from this page?</p>
                    <p>You will lose all changes made since you last save.</p>
                    <p>Do you want to save changes before exit?</p>
                </div>

                <div className='inline-flex gap-2 bg-gray-100 w-full justify-end items-center px-6 h-[75px]'>
                    {/* <Button type='submit' onClick={handleSubmit(onSubmit)} className='bg-[#3a671a]'>
              Save
          </Button> */}
                    <Button
                        onClick={() => {
                            props.handleDiscard()
                        }}
                        className='w-97'
                        variant={'outline'}
                    >
                        Discard
                    </Button>
                    <Button
                        className='w-97'
                        onClick={() => {
                            props.onSubmitDraft()
                            props.onClose()
                        }}
                    >
                        Save as Draft
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DiscardModal
