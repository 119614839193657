import { cn } from '@/utils/helper'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import ProductServices from './ProductsServices'
import Profile from '../Profile'
import { useLocation, useNavigate } from 'react-router-dom'
import OwnerSubscription from '../OwnerSubscription'
import PaymentHistory from '../OwnerSubscription/PaymentHistory'
import ChangePasswordForm from '../Profile/ChangePasswordForm'
import { useAtomValue } from 'jotai'
import { ROLE } from '@/constants'
import CreateService from './ProductsServices/CreateService'
import Reimbursements from './Reimbursements'
import StripeAccount from './StripeAccount'
import { isFreeTrialEndedAtom } from '@/store/owner'
import ExpenseAccounts from './ExpenseAccounts'
import CreateNewExpenseAccount from './ExpenseAccounts/CreateNewExpenseAccount'
import useStripeConnectStatus from '@/hooks/useStripeConnectStatus'
import QuickBooks from './QuickBooks'
import MailChimpIntegration from './MailChimpIntegration'
import { CreateCoOwner } from './CoOwner/CreateCoOwner'
import { CoOwner } from './CoOwner'
import { Discounts } from './Discounts'
import { CreateDiscount } from './Discounts/CreateDiscount'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const TAB_TRIGGER_CLASS =
    'w-full p-2 bg-white hover:bg-zentive-gray-light flex justify-start text items-center text-[16px] font-sans font-regular text-zentive-black bg-zentive-background'
const TAB_TRIGGER_CLASS_2 =
    'data-[state=active]:bg-zentive-gray-light data-[state=active]:text-zentive-blue-dark data-[state=active]:font-semibold'

const Settings = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const user = useViewedAsUser()
    const isFreeTrialEnded = useAtomValue(isFreeTrialEndedAtom)

    const paths = pathname.split('/')
    const currentTab = paths.pop() ?? 'profile'

    const isOwner = user?.role?.roleName === ROLE.OWNER
    const isCoOwner = user?.role?.roleName === ROLE.CO_OWNER

    const handleTabChange = (tab: string) => navigate(`/settings/${tab}`)

    const { data: isConnectedToStripe } = useStripeConnectStatus()

    return (
        <div>
            <Tabs
                orientation='horizontal'
                defaultValue={currentTab}
                onValueChange={(val) => handleTabChange(val)}
                value={currentTab}
                className={`mt-[18px] flex flex-row w-full ${isFreeTrialEnded && 'justify-center'}`}
            >
                <TabsList
                    className={`${
                        isFreeTrialEnded
                            ? 'hidden'
                            : `md:w-[22%] 
                2xl:w-[18%] 
                w2xl:w-[17%]
                3xl:w-[13%] 
                4xl:w-[9%] 
                mt-[26px] 
                h-full`
                    }`}
                >
                    <p className='ml-4 mb-8 text-zentive-gray-medium font-semibold text-2xl'>
                        Settings
                    </p>
                    <TabsTrigger
                        value='profile'
                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                    >
                        <p className='ml-4'>My Account</p>
                    </TabsTrigger>
                    <TabsTrigger
                        value='change-password'
                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                    >
                        <p className='ml-4'>Change Password</p>
                    </TabsTrigger>
                    {user?.role?.roleName === ROLE.ADMIN && (
                        <TabsTrigger
                            value='discounts'
                            className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                        >
                            <p className='ml-4'>Discounts</p>
                        </TabsTrigger>
                    )}

                    {isOwner && (
                        <>
                            <TabsTrigger
                                value='owner-subscription'
                                className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                            >
                                <p className='ml-4'>My Subscription</p>
                            </TabsTrigger>
                            {isOwner && !isConnectedToStripe && (
                                <>
                                    <TabsTrigger
                                        value='stripe-account'
                                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                                    >
                                        <p className='ml-4'>Stripe Account</p>
                                    </TabsTrigger>
                                </>
                            )}
                            {isOwner && (
                                <TabsTrigger
                                    value='payment-history'
                                    className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                                >
                                    <p className='ml-4'> Payment History</p>
                                </TabsTrigger>
                            )}
                            {(isOwner || isCoOwner) && (
                                <>
                                    <TabsTrigger
                                        value='product-services'
                                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                                    >
                                        <p className='ml-4'>Products & Services</p>
                                    </TabsTrigger>
                                    <TabsTrigger
                                        value='expense-accounts'
                                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                                    >
                                        <p className='ml-4'>Expense Account</p>
                                    </TabsTrigger>
                                </>
                            )}
                            {isOwner && isConnectedToStripe && (
                                <TabsTrigger
                                    value='reimbursement'
                                    className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                                >
                                    <p className='ml-4'>Service Reimbursements</p>
                                </TabsTrigger>
                            )}
                            {isOwner && (
                                <>
                                    <TabsTrigger
                                        value='quickbooks'
                                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                                    >
                                        <p className='ml-4'>Quickbooks Integration</p>
                                    </TabsTrigger>
                                    <TabsTrigger
                                        value='mail-chimp'
                                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                                    >
                                        <p className='ml-4'>MailChimp Integration</p>
                                    </TabsTrigger>
                                    <TabsTrigger
                                        value='co-owner'
                                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)}
                                    >
                                        <p className='ml-4'>Add User</p>
                                    </TabsTrigger>
                                </>
                            )}
                        </>
                    )}
                </TabsList>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%]
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='profile'
                >
                    <Profile />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='change-password'
                >
                    <ChangePasswordForm />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='owner-subscription'
                >
                    <OwnerSubscription />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='stripe-account'
                >
                    <StripeAccount />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='payment-history'
                >
                    <PaymentHistory />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%] 
                    mt-5'
                    value='product-services'
                >
                    <ProductServices />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%] 
                    mt-5'
                    value='product-service-add-item'
                >
                    <CreateService />
                </TabsContent>

                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%] 
                    mt-5'
                    value='expense-accounts'
                >
                    <ExpenseAccounts />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%] 
                    mt-5'
                    value='add'
                >
                    <CreateNewExpenseAccount />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='reimbursement'
                >
                    <Reimbursements />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='quickbooks'
                >
                    <QuickBooks />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='mail-chimp'
                >
                    <MailChimpIntegration />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='co-owner'
                >
                    <CoOwner />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='add-co-owner'
                >
                    <CreateCoOwner />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='discounts'
                >
                    <Discounts />
                </TabsContent>
                <TabsContent
                    className='rounded-md 
                    md:w-[75%] 
                    2xl:w-[79%] 
                    w2xl:w-[80.5%] 
                    3xl:w-[85%] 
                    4xl:w-[89.6%]'
                    value='create-discount'
                >
                    <CreateDiscount />
                </TabsContent>
            </Tabs>
        </div>
    )
}

export default Settings
