import { createCustomerMessaging, getCustomerMessageByID } from '@/api/customer'
import {
    CreateGroupAndMessagingType,
    MessageListType,
    createGroupAndMessagingSchema,
} from '@/api/customer/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useToast } from '@/hooks/useToast'
import { customerContactContentAtom } from '@/store/customer'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import MessageComposition from './MessageComposition'
import { cn } from '@/utils/helper'
import { timezoneAtom } from '@/store/auth'
import {
    BREADCRUMBS_PADDING_STYLE,
    FEATURE_RESTRICTIONS,
    ISO_8601_WITH_UTC_OFFSET,
    MESSAGE_TYPE,
    MSG_TYPES,
} from '@/constants'
import DiscardModal from './DiscardModal'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import { UpgradeBadge } from '@/components/UpgradeBadge'
import dayjs from 'dayjs'
import { TimeZoneResult } from '@/hooks/useGeocode'
import useViewedAsUser from '@/hooks/useViewedAsUser'

type ResponseType = {
    message: string
}

const CreateEmail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { toast } = useToast()

    const [messageType, setMessageType] = useState<string>(MESSAGE_TYPE.DRAFT)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const [isDraft, setIsDraft] = useState<boolean>(false)

    const [selected, setSelected] = useAtom(customerContactContentAtom)

    const user = useViewedAsUser()
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const handleSuccess = (data: MessageListType) => {
        methods.setValue('type', data.type)
        methods.setValue('emailBody', data.emailBody)
        methods.setValue('messageId', data.messageId)
        methods.setValue('title', data.title)
        methods.setValue('subject', data.subject)
        methods.setValue('smsBody', data.smsBody)
        methods.setValue('dateSent', data.dateSent)
        setMessageType(data.type)
        setSelected(data.recipients ?? [])
    }

    const { mutate: getMessage } = useMutation<MessageListType, AxiosError, string>({
        mutationKey: ['specificMessage'],
        mutationFn: () => getCustomerMessageByID(id ?? ''),
        onSuccess: handleSuccess,
    })

    const methods = useForm<CreateGroupAndMessagingType>({
        defaultValues: { status: 'sent', profileId: user.profileId },
        mode: 'onSubmit',
        resolver: zodResolver(createGroupAndMessagingSchema),
    })

    const formValues = methods.watch()

    const { mutate: sendMessageMu } = useMutation<
        ResponseType,
        AxiosError,
        CreateGroupAndMessagingType
    >({
        mutationKey: ['SendMessage'],
        mutationFn: (data) => createCustomerMessaging(data),
        onSuccess: () => {
            const toastMessage = isDraft ? 'Draft saved' : 'Message sent successfully'
            toast({
                description: toastMessage,
                variant: 'default',
            })
            setSelected([])
            navigate('/customers/customer-messaging')
        },
    })

    const onSubmit = (data: CreateGroupAndMessagingType) => {
        if (isSubmitting) return

        setIsSubmitting(true)
        setIsDraft(false)

        setTimeout(() => {
            methods.setValue('profileId', user.profileId)
            sendMessageMu(data)
        }, 500)

        setTimeout(() => {
            setIsSubmitting(false)
        }, 1000)
    }

    const onSubmitDraft = () => {
        if (isSubmitting) return

        setIsSubmitting(true)
        setIsDraft(true)

        setTimeout(() => {
            methods.setValue('status', 'draft')
            sendMessageMu(methods.getValues())
        }, 500)

        setTimeout(() => {
            setIsSubmitting(false)
        }, 1000)
    }

    const isFormFilled = () => {
        const titleEmpty = formValues.title === '' || formValues.title === undefined
        const recipientsEmpty =
            formValues.recipients === undefined || formValues.recipients?.length === 0

        const subjectEmpty = formValues.subject === '' || formValues.subject === undefined

        const emailBodyEmpty =
            formValues.emailBody === '' ||
            formValues.emailBody === undefined ||
            formValues.emailBody === '"<p></p>"'

        const smsBodyEmpty = formValues.smsBody === '' || formValues.smsBody === undefined

        const isDetailsEmpty = !titleEmpty && !recipientsEmpty

        if (messageType === MESSAGE_TYPE.EMAIL) {
            return isDetailsEmpty && !subjectEmpty && !emailBodyEmpty
        }
        if (messageType === MESSAGE_TYPE.SMS) {
            return isDetailsEmpty && !smsBodyEmpty
        }
        if (messageType === MESSAGE_TYPE.DRAFT) {
            return isDetailsEmpty && !subjectEmpty && !emailBodyEmpty
        }
        return isDetailsEmpty && !subjectEmpty && !smsBodyEmpty && !emailBodyEmpty
    }

    const handleDiscard = () => {
        setSelected([])
        navigate('/customers/customer-messaging')
    }

    const { data: isFeatureAvailable, isLoading: featurePending } = useQuery<boolean>({
        queryKey: ['messagingTierRestriction', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId,
                FEATURE_RESTRICTIONS.customerMessaging,
            ),
    })

    const handleMessageTypeChange = (msgType: string) => {
        if (msgType === MSG_TYPES.sms || msgType === MSG_TYPES.both) {
            setRestrictionModal(!isFeatureAvailable)

            if (isFeatureAvailable) {
                setMessageType(msgType)
            }
        } else {
            setMessageType(msgType)
        }
    }

    useEffect(() => {
        const initialType = messageType === 'draft' ? 'email' : messageType

        methods.setValue('sendSchedule', 'now')
        methods.setValue('type', initialType)
        methods.setValue(
            'dateSent',
            dayjs().tz(timezone.timeZoneId).format(ISO_8601_WITH_UTC_OFFSET),
        )
    }, [messageType])

    useEffect(() => {
        if (selected && selected?.length > 0) {
            const selectedIds = selected?.map((ids) => ids.profileId)
            return methods.setValue('recipients', selectedIds)
        }

        methods.setValue('recipients', [])
    }, [selected])

    useEffect(() => {
        if (id) {
            getMessage(id)
        }
    }, [id])

    return (
        <Form {...methods}>
            <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
                <BreadCrumbs titleName='Create Message' />
                <div className='mt-10 pl-24 pr-8 py-24 bg-white rounded-md space-y-10'>
                    <div className='flex space-x-28 text-center items-center'>
                        <p>
                            Title<span className='text-red-500'>*</span>
                        </p>
                        <div className='flex-1'>
                            <FormField
                                control={methods.control}
                                name='title'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                className='font-sans pl-3 w-full text-base h-11 border border-gray-20 rounded-sm'
                                                type='text'
                                                placeholder='Give your message a title'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row space-x-28 text-center items-center'>
                        <p>Send</p>
                        <Button
                            type='button'
                            className='bg-gray-100 text-black hover:bg-gray-100 px-8 py-3 h-10'
                            onClick={() => methods.setValue('sendSchedule', 'now')}
                        >
                            Send Immediately
                        </Button>
                    </div>
                    <div className='flex flex-row gap-5'>
                        <div className='flex flex-row space-x-28 text-center items-center'>
                            <p>Type</p>
                            <select
                                name='row'
                                value={messageType}
                                onChange={(val) => handleMessageTypeChange(val.target.value)}
                                className='bg-gray-100 pl-3 pr-24 py-1 border border-gray-200 rounded-md text-left h-10'
                                disabled={featurePending}
                            >
                                <option disabled>Select</option>
                                <option role='text' value={MSG_TYPES.email}>
                                    Email
                                </option>
                                <option role='text' value={MSG_TYPES.sms}>
                                    SMS
                                </option>
                                <option role='text' value={MSG_TYPES.both}>
                                    Both
                                </option>
                            </select>
                        </div>
                        {!isFeatureAvailable && !featurePending && (
                            <div>
                                <UpgradeBadge label='Upgrade Required for SMS' />
                            </div>
                        )}
                    </div>
                    <hr />
                    <MessageComposition messageType={messageType} />
                    <div className='flex pt-5 justify-between'>
                        <Button
                            type='button'
                            onClick={() => setIsModalOpen(true)}
                            className='border border-green-800 text-green-800 bg-white hover:bg-white px-8 py-5'
                        >
                            Back
                        </Button>
                        <div className='space-x-3'>
                            <Button
                                type='button'
                                onClick={onSubmitDraft}
                                className={cn(
                                    !isFormFilled() ? 'cursor-not-allowed' : '',
                                    'border border-green-800 text-green-800 bg-white hover:bg-white px-8 py-5',
                                )}
                                disabled={!isFormFilled() || isSubmitting}
                            >
                                Save as Draft
                            </Button>
                            <Button
                                type='submit'
                                onClick={methods.handleSubmit(onSubmit)}
                                className={cn(
                                    !isFormFilled() ? 'cursor-not-allowed' : '',
                                    'border bg-green-800 text-white hover:bg-green-800 px-8 py-5',
                                )}
                                disabled={!isFormFilled() || isSubmitting}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <DiscardModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                handleDiscard={handleDiscard}
                onSubmitDraft={onSubmitDraft}
            />
            <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </Form>
    )
}

export default CreateEmail
