import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import { useAtom, useAtomValue } from 'jotai'
import { getAllEmployees } from '@/api/employee'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { paginationAtom } from '@/store/manageSubs'
import {
    employeeSortByAtom,
    employeeSortOrderAtom,
    selectedEmployeeTabStatusAtom,
    toExportEmployeeAtom,
} from '@/store/employee'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { BulkProfileUpdateStatusType, FullProfileType } from '@/api/profile/schema'
import { Pagination } from '@/components/Pagination'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import AddEmployeeDropdown from './AddEmployeeDropdown'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { BREADCRUMBS_PADDING_STYLE, USER_STATUS } from '@/constants'
import DeleteModal from './ManageEmployees/EmployeeModals/BulkActionModal'
import EmployeeTable from './EmployeeTable'
import ExportDropdown from './ExportDropdown'
import SearchBar from '@/components/InputSearchBox'
import BreadCrumbs from '@/components/BreadCrumbs'
import { cn } from '@/utils/helper'
import debounce from 'debounce'

const TAB_TRIGGER_CN =
    'w-1/3 h-[54px] text-lg text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate'

const Employee = () => {
    const [selected, setSelected] = useAtom(selectedEmployeeTabStatusAtom)
    const [pagination, setPagination] = useAtom(paginationAtom)
    console.log("🚀 ~ Employee ~ pagination:", pagination)
    const toExportEmployee = useAtomValue(toExportEmployeeAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const employeeSortBy = useAtomValue(employeeSortByAtom)
    const employeeSortOrder = useAtomValue(employeeSortOrderAtom)

    const [keyWord, setKeyWord] = useState<string>('')
    const [_, setIsModalOpen] = useState<boolean>(false)

    useEffect(() => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            pageSize: 5,
        }))
    },[])

    const [profilesToUpdate, setProfilesToUpdate] = useState<BulkProfileUpdateStatusType>({
        profileIds: [],
        status: '',
    })

    const { isLoading: loading, data: employeeListData } = useQuery({
        queryKey: ['employeeList', user.businessId, selected, keyWord, pagination, employeeSortBy, employeeSortOrder],
        queryFn: () =>
            getAllEmployees(
                keyWord.trim() ?? '',
                selected,
                user.businessId,
                pagination,
                { sortBy: employeeSortBy, sortOrder: employeeSortOrder }
            ),
    })

    const handleSearch = (query: string) => {
        setKeyWord(query)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }

    const debouncedSearch = debounce((query: string) => {
        handleSearch(query)
    }, 500)

    const isDirty = () => {
        if (!toExportEmployee?.content?.length) return true
        if (!employeeListData?.content?.length) return true
        else return false
    }

    useEffect(() => {
        const getStatus = (selected: string) => {
            switch (selected) {
                case USER_STATUS.ACTIVATED:
                    return USER_STATUS.DELETED
                // Both INA and DEL will update using ACT status
                case USER_STATUS.INACTIVE:
                case USER_STATUS.DELETED:
                    return USER_STATUS.ACTIVATED
            }
        }
        setProfilesToUpdate(() => ({
            profileIds: [],
            status: getStatus(selected),
        }))

        setPagination({
            page: 1,
            pageSize: 5,
        })
    }, [selected])

    return (
        <div className={cn('w-full h-full', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs titleName='Employees' />
            <div className='flex justify-between py-6'>
                <div>
                    <SearchBar
                        placeHolder='Search for...'
                        onSearchChange={(e) => debouncedSearch(e.target.value)}
                    />
                </div>
                {employeeListData && (
                    <div className='flex gap-x-4'>
                        <DeleteModal
                            profilesToUpdate={profilesToUpdate}
                            setOpen={setIsModalOpen}
                            isEmployeeTable={true}
                            onSuccessRestore={setProfilesToUpdate}
                        />
                        <ExportDropdown
                            employeeListData={toExportEmployee ?? { content: [], meta: {} }}
                            isDisabled={isDirty()}
                        />
                        <AddEmployeeDropdown />
                    </div>
                )}
            </div>
            <Tabs value={selected} onValueChange={(val) => setSelected(val)}>
                <TabsList className='w-full flex flex-row bg-white rounded-md'>
                    <TabsTrigger value='ACT' className={TAB_TRIGGER_CN}>
                        Active
                        {/* TO DO: ({employeeListData && employeeListData.content.filter((item) => item.status === 'ACT')?.length}) */}
                    </TabsTrigger>
                    <TabsTrigger value='INA' className={TAB_TRIGGER_CN}>
                        Deactivated
                        {/* TO DO: ({employeeListData && employeeListData.content.filter((item) => item.status === 'INA')?.length}) */}
                    </TabsTrigger>
                    <TabsTrigger
                        value='DEL'
                        className={
                            'w-1/3 h-[54px] text-lg text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        Removed{' '}
                        {/* TO DO: ({employeeListData && employeeListData.content.filter((item) => item.status === 'TER')?.length}) */}
                    </TabsTrigger>
                </TabsList>
            </Tabs>
            {loading && <AppSkeletonLoadingState />}
            {!loading && !!employeeListData?.content?.length && (
                <EmployeeTable
                    employeeListData={employeeListData}
                    setProfilesToUpdate={setProfilesToUpdate}
                    profilesToUpdate={profilesToUpdate}
                />
            )}

            {!!employeeListData?.content?.length && (
                <div className='mb-5 mt-3'>
                    {pagination.pageSize !== undefined && (
                        <Pagination
                            itemsPerPage={pagination.pageSize}
                            setPagination={setPagination}
                            totalRecords={
                                employeeListData ? employeeListData?.meta?.totalRecords ?? 1 : 1
                            }
                            pagination={pagination}
                        />
                    )}
                </div>
            )}
            {employeeListData && employeeListData?.content?.length <= 0 && (
                <div className='bg-white rounded-sm py-14'>
                    <div className='flex justify-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-12 h-60'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
                </div>
            )}
        </div>
    )
}

export default Employee
