import { Card, CardContent, CardFooter } from '@/components/Card'
import { getBillingInfo, updateBillingInfo } from '@/api/profile'
import PersonalInfo from './PersonalInfo'
import BillingAdd from './BillingAddress'
import { Form } from '@/components/Forms'
import { useForm } from 'react-hook-form'
import { Button } from '@/components/Button'
import { billingInfoAtom } from '@/store/customer'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import { BillingInfoType, billingInfoSchema } from '@/api/profile/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { toast } from '@/hooks/useToast'
import { userAtom } from '@/store/auth'
import { useNavigate } from 'react-router-dom'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const BillingInfo = () => {
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)
    const queryClient = useQueryClient()
    const setBillingInfo = useSetAtom(billingInfoAtom)
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true)

    const { data: billingInfoData } = useQuery<unknown, AxiosError, BillingInfoType>({
        queryKey: ['billingInfo'],
        queryFn: () => getBillingInfo(user?.profileId as string), // replace this once getAllSubs is already integrated
        retry: 0,
    })

    const handleError = (err: AxiosError) => console.error(err.message)

    const updateMethods = useForm<BillingInfoType>({
        defaultValues: {
            customerID: billingInfoData?.customerID,
            email: billingInfoData?.email,
            firstName: billingInfoData?.firstName,
            lastName: billingInfoData?.lastName,
            contactNumber: billingInfoData?.contactNumber,
            lastFourNo: billingInfoData?.lastFourNo,
            address: {
                ...billingInfoData?.address,
            },
        },
        mode: 'onChange',
        resolver: zodResolver(billingInfoSchema),
    })

    const { setValue, handleSubmit, watch } = updateMethods
    const watchedFields = watch()

    const { mutate: getBillingInfoMu } = useMutation({
        mutationFn: (data: string) => getBillingInfo(data),
        onSuccess: (res) => {
            setBillingInfo(res)
            toast({
                description: 'Successfully Updated',
                variant: 'default',
            })
        },
        onError: handleError,
    })

    const { mutate: updateBillingInfoMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: BillingInfoType) =>
            updateBillingInfo(data).then(() => getBillingInfoMu(user?.profileId as string)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['businessProfile'] })
            navigate('/customer-portal/payments')
        },
        onError: (err) => console.log(err),
    })

    useEffect(() => {
        if (billingInfoData) {
            setBillingInfo(billingInfoData)
            setValue('customerID', billingInfoData.customerID)
            setValue('lastFourNo', billingInfoData.lastFourNo)
            setValue('firstName', billingInfoData.firstName)
            setValue('lastName', billingInfoData.lastName)
            setValue('contactNumber', billingInfoData.contactNumber)
            setValue('email', billingInfoData.email)
            setValue('address.streetOne', billingInfoData.address.streetOne)
            setValue('address.streetTwo', billingInfoData.address.streetTwo)
            setValue('address.city', billingInfoData.address.city)
            setValue('address.state', billingInfoData.address.state)
            setValue('address.zipCode', billingInfoData.address.zipCode)
        }
    }, [billingInfoData])

    const onSubmit = (data: BillingInfoType) => {
        updateBillingInfoMu(data)
    }

    useEffect(() => {
        if (billingInfoData) {
            const isEmptyField =
                !watchedFields.firstName ||
                !watchedFields.lastName ||
                !watchedFields.email ||
                !watchedFields.contactNumber

            const isDisabled =
                isEmptyField ||
                updateLoading ||
                (watchedFields.firstName === billingInfoData?.firstName &&
                    watchedFields.lastName === billingInfoData?.lastName &&
                    watchedFields.email === billingInfoData?.email &&
                    watchedFields.contactNumber === billingInfoData?.contactNumber)
            setButtonDisabled(isDisabled)
        }
    }, [billingInfoData, watchedFields, updateLoading])

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Billing Information' />
            <Card className='mt-5 w-full'>
                <CardContent>
                    <Form {...updateMethods}>
                        <form
                            className='relative h-full flex flex-col'
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Card className=' border-none'>
                                <CardContent className='px-6 pb-20 pt-6'>
                                    <PersonalInfo />
                                    <BillingAdd />
                                </CardContent>
                                <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] '>
                                    <div className='flex space-x-4 mt-[24px]'>
                                        <Button
                                            onClick={() => navigate(-1)}
                                            type='button'
                                            variant={'outline'}
                                            className='w-97 mr-3 text-zentive-green-dark font-semibold'
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            disabled={isButtonDisabled}
                                            onClick={handleSubmit(onSubmit, (err) => {
                                                console.log(err)
                                                toast({
                                                    description:
                                                        'Unable to pay now please set up your payment method first',
                                                })
                                            })}
                                            type='submit'
                                            className='w-97 mr-5'
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </CardFooter>
                            </Card>
                        </form>
                    </Form>
                </CardContent>
            </Card>
        </div>
    )
}
export default BillingInfo
