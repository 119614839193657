import { APPOINTMENT_TYPES } from '@/constants'
import { axiosInstance } from '../axiosInstance'
import {
    CompleteInprogressJobType,
    CreateJobWithoutQuoteType,
    CreateJobWithQuoteType,
    CreateNewJobImageType,
    JobDtrType,
    JobFilterStatusType,
    JobListType,
    JobResponseType,
    JobsQuoteProfileType,
    ReadJobType,
    RescheduleJobType,
    UpdateCrewDtrType,
    UpdateJobStatusCrewInterfaceType,
    UpdateJobStatusType,
    UpdateJobType,
} from './schema'
import { PaginationType } from '@/components/Pagination/schema'
import { NotePayloadType } from '../notes/schema'
import dayjs from 'dayjs'

export interface UpdateJobTime {
    jobId: string
    crewId: string
    startDateTime: string
    endDateTime: string
    appointmentType: string
}

export interface AssignCrewToJobParams {
    jobId: string
    crewId: string
}

export interface SortParams {
    sortBy?: string
    sortOrder?: string
}

export interface GetJobLogsByBusinessIdParams {
    businessId: string
    keyword: string
    crewIds: string[]
    status: string
    p: PaginationType
    sortBy?: string
    sortOrder?: string
}

export interface GetJobsByCrewMemberIdParams {
    businessId: string
    crewMemberId: string
    profileId: string
    keyword: string
    status: string
    startDate: string
    endDate: string
}

export interface GetJobsByCrewMemberDateFilter {
    startDate: string
    endDate: string
}

export interface CreateNewJobImagesParams {
    jobId: string
    jobImages: string
}

export interface UpdateJobAttachmentDescription {
    jobId: string
    description: string
}

export interface CreateCrewMemberDTRParams {
    time: string
    jobCrewMemberId: string
    jobId: string
    type: string
}

interface GetLastJobByClientParams {
    businessId: string
    crewMemberId: string
    jobId: string
    customerId: string
    profileId: string
}

export interface RemoveJobImagesParams {
    jobImageIds: string[]
}

export const getJobById = async (jobId: string | undefined): Promise<ReadJobType> => {
    if (!jobId) {
        throw new Error('Job ID not found')
    }

    const response = await axiosInstance.get(`jobs/${jobId}`)
    return response.data.content
}

export const getJobByIdCrewInterface = async (
    jobId: string | undefined,
    businessId: string | undefined,
): Promise<ReadJobType> => {
    if (!jobId) {
        throw new Error('Job ID not found')
    }

    if (!businessId) {
        throw new Error('Job ID not found')
    }

    const response = await axiosInstance.get(
        `jobs/view-crew-job?jobId=${jobId}&businessId=${businessId}`,
    )
    return response.data.content
}

export const getJobsByCustomerProfileID = async (
    profileId: string,
    businessId: string,
    p: PaginationType,
    sort: SortParams,
) => {
    const { page, pageSize } = p

    const params = {
        profileID: profileId,
        businessID: businessId,
        page: page,
        pageSize: pageSize,
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
    }

    const response = await axiosInstance.get(`jobs/get-jobs-customer`, { params })

    return response.data as JobListType
}

export const getAllJobsByCustomerProfileID = async (profileId: string, businessId: string) => {
    const response = await axiosInstance.get(
        `jobs/get-all-jobs-customer?businessID=${businessId}&profileID=${profileId}`,
    )
    return response.data as JobsQuoteProfileType
}

export const getLastJobByClient = async (data: GetLastJobByClientParams) => {
    const response = await axiosInstance.get(
        `jobs/last-job-date?businessId=${data.businessId}&crewMemberId=${data.crewMemberId}&jobId=${data.jobId}&profileId=${data.profileId}&customerId=${data.customerId}`,
    )
    return response.data.content as ReadJobType
}

export const getJobsByBusinessId = async (
    businessId: string,
    keyword: string,
    assignStatus: string,
    p: PaginationType,
    jobDate: string,
    sort: SortParams,
    statusFilter?: JobFilterStatusType,
) => {
    const { page, pageSize } = p

    const params: any = {
        businessId: businessId,
        keyword: keyword,
        assignStatus: assignStatus,
        page: page,
        pageSize: pageSize,
        jobDate: jobDate,
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
    }

    const searchParams = new URLSearchParams(params)

    if (statusFilter?.length) {
        statusFilter.forEach((status) => {
            searchParams.append('jobStatus', status)
        })
    }

    const response = await axiosInstance.get(`jobs/business?${searchParams.toString()}`)

    return response.data as JobListType
}

export const getJobsCalendar = async (
    businessId: string,
    start: string,
    end: string,
    assignStatus: string,
): Promise<JobListType> => {
    const response = await axiosInstance.get(`jobs/calendar`, {
        params: {
            businessId: businessId,
            start: start,
            end: end,
            assignStatus: assignStatus,
        },
    })
    return response.data.content as JobListType
}

export const getJobLogsByBusinessId = async (data: GetJobLogsByBusinessIdParams) => {
    const response = await axiosInstance.post(
        `jobs/business/logs?page=${data.p.page}&pageSize=${data.p.pageSize}`,
        data,
    )
    return response.data as JobListType
}

export const getJobsByCrewMemberId = async (
    data: GetJobsByCrewMemberIdParams,
): Promise<JobListType> => {
    const response = await axiosInstance.post(`jobs/crew-member`, data)
    return response.data
}

export const getJobListByCrewMemberId = async (
    data: GetJobsByCrewMemberIdParams,
): Promise<JobListType> => {
    const response = await axiosInstance.get(`jobs/crew-member`, {
        params: {
            businessID: data.businessId,
            crewMemberID: data.crewMemberId,
            endDate: data.endDate,
            keyword: data.keyword,
            profileID: data.profileId,
            startDate: data.startDate,
            status: data.status,
        },
    })

    return response.data
}

export const createCrewMemberDtr = async (data: CreateCrewMemberDTRParams) => {
    const response = await axiosInstance.post(`dtr`, data)
    return response.data.content as JobDtrType
}

export const getCrewMemberDtr = async (jobCrewMemberId: string | undefined) => {
    if (!jobCrewMemberId) {
        throw new Error('Job Crew Member ID not found')
    }

    const response = await axiosInstance.get(`dtr/employee-time-record/${jobCrewMemberId}`)
    return response.data
}

export const getJobNumber = async (businessId: string) => {
    const res = await axiosInstance.get(`/jobs/next-job-number/${businessId}`)
    return res.data.content
}

export const assignCrewToJob = async (data: AssignCrewToJobParams) => {
    const res = await axiosInstance.put(`/jobs/assign-crew`, data)
    return res.data as JobResponseType
}

export const updateJobStatus = async (data: UpdateJobStatusType) => {
    const res = await axiosInstance.patch(
        `/jobs/update/status?jobId=${data.jobId}&status=${data.status}`,
    )
    return res.data as JobResponseType
}

export const updateJobStatusCrewInterface = async (data: UpdateJobStatusCrewInterfaceType) => {
    const res = await axiosInstance.patch(`/jobs/crew/update/status`, data)
    return res.data as JobResponseType
}

export const convertQuoteToJob = async (
    data: CreateJobWithQuoteType | CreateJobWithoutQuoteType,
) => {
    const formData = new FormData()

    formData.append('addressId', data.address?.addressId ?? data.addressId)
    formData.append('businessId', data.businessId)
    formData.append('crewId', data.crewId ?? '')

    if ('quoteId' in data) {
        formData.append('quoteId', data.quoteId)
    }
    formData.append('availableAnytime', data?.appointment?.availableAnytime.toString())

    if (data.foreman?.crewMemberId) {
        formData.append('foremanId', data?.foreman.crewMemberId as string)
    }

    if (data.crewMemberIds && data.crewMemberIds?.length) {
        data?.crewMemberIds?.forEach((memberId) => {
            formData.append(`crewMemberIds`, memberId)
        })
    }

    if (
        data.appointment.repeat &&
        data.appointment.appointmentType === APPOINTMENT_TYPES.RECURRING
    ) {
        formData.append(
            'appointmentType',
            `${APPOINTMENT_TYPES.RECURRING}_${data.appointment.repeat}`,
        )

        formData.append('repeat', data.appointment.repeat)
    } else {
        formData.append('appointmentType', APPOINTMENT_TYPES.ONEOFF_JOB)
    }

    formData.append('startDateTime', data.appointment.startDateTime)
    formData.append('endDateTime', data.appointment.endDateTime)

    const startDateFormat = dayjs(data.appointment.startDateTime)
    const endDateFormat = dayjs(data.appointment.endDateTime)
    const differenceInHours = endDateFormat.diff(startDateFormat, 'hours')
    formData.append('timeDifference', differenceInHours as unknown as string)

    // Attachment fields with photos
    formData.append('description', data?.description || '')
    formData.append('note', data?.note || '')

    if (data?.jobImages && data?.jobImages?.length) {
        data?.jobImages?.forEach((image) => {
            formData.append(`jobImages`, image)
        })
    }

    if (
        data.appointment.repeat &&
        data.appointment.appointmentType.includes(APPOINTMENT_TYPES.RECURRING)
    ) {
        const res = await axiosInstance.post('/jobs/recurring', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return res.data.content
    } else {
        const res = await axiosInstance.post('/jobs', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return res.data.content
    }
}

export const updateJob = async (data: UpdateJobType) => {
    const formData = new FormData()

    formData.append('addressId', data.addressId ?? '')
    formData.append('businessId', data.businessId)
    formData.append('jobId', data.jobId as string)
    formData.append('crewId', data.crewId ?? '')

    // Serialize the appointment object to a JSON string
    formData.append('availableAnytime', String(data.availableAnytime))

    // Add description and job images if available
    formData.append('description', data.description || '')

    if (data.jobImages && data.jobImages.length) {
        data.jobImages.forEach((image) => {
            formData.append('jobImages', image)
        })
    }

    if (data.foreman?.crewMemberId) {
        formData.append('foremanId', data.foreman.crewMemberId as string)
    }

    if (data.crewMemberIds && data.crewMemberIds.length) {
        data.crewMemberIds.forEach((memberId) => {
            formData.append('crewMemberIds', memberId)
        })
    }

    const res = await axiosInstance.patch('/jobs', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return res.data.content
}

export const rescheduleJob = async (data: RescheduleJobType) => {
    const formData = new FormData()

    formData.append('jobId', data.jobId as string)
    formData.append('crewId', data.crewId ?? '')
    formData.append('appointmentId', data.appointment.appointmentId ?? '')
    formData.append('availableAnytime', data?.appointment?.availableAnytime.toString())
    formData.append('appointmentType', APPOINTMENT_TYPES.ONEOFF_JOB)
    formData.append('startDateTime', data.appointment.startDateTime)
    formData.append('endDateTime', data.appointment.endDateTime)

    // Attachment fields with photos
    formData.append('description', data?.description || '')

    if (data?.jobImages && data?.jobImages?.length) {
        data?.jobImages?.forEach((image) => {
            formData.append(`jobImages`, image)
        })
    }

    if (data.foreman?.crewMemberId) {
        formData.append('foremanId', data?.foreman.crewMemberId as string)
    }

    if (data.crewMemberIds && data.crewMemberIds?.length) {
        data?.crewMemberIds?.forEach((memberId) => {
            formData.append(`crewMemberIds`, memberId)
        })
    }

    const res = await axiosInstance.patch('/jobs/reschedule', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    console.log("🚀 ~ rescheduleJob ~ res:", res.data.content)

    return res.data.content
}

export const updateJobAppointmentTime = async (props: UpdateJobTime) => {
    const response = await axiosInstance.patch(`jobs/update-time`, props)
    return response.data
}

export const updateCrewDtr = async (data: UpdateCrewDtrType) => {
    const response = await axiosInstance.post(`dtr/update/time`, data)
    return response.data as string
}

export const getJobNoteByNoteId = async (noteId: string): Promise<NotePayloadType> => {
    const response = await axiosInstance.get(`jobs/note?noteId=${noteId}`)
    return response.data.content
}

export const createJobNote = async (props: NotePayloadType) => {
    const response = await axiosInstance.post(`jobs/notes/add`, props)
    return response.data
}

export const resetJobProgress = async (jobId: string) => {
    const response = await axiosInstance.post(`jobs/reset-progress/${jobId}`)
    return response.data
}

export const createNewJobImage = async (data: CreateNewJobImageType, jobId: string) => {
    const formData = new FormData()

    if (data?.jobImages && data?.jobImages?.length) {
        data?.jobImages?.forEach((image) => {
            formData.append(`jobImages`, image)
        })
    }

    const res = await axiosInstance.post(`/jobs/photo/add?jobId=${jobId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
    return res.data.content
}

export const removeJobImages = async (data: RemoveJobImagesParams) => {
    const response = await axiosInstance.patch(`/jobs/photo/remove`, data)
    return response.data as string
}

export const updateJobAttachmentDescription = async (data: UpdateJobAttachmentDescription) => {
    const response = await axiosInstance.patch(`/jobs/attachment-description`, data)
    return response.data as ReadJobType
}

export const completeInprogressJob = async (data: CompleteInprogressJobType) => {   
    const response = await axiosInstance.post('/jobs/complete-in-progress', data)

    return response.data
}