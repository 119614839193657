import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { updateOwnerSubscriptionStatus } from '@/api/owner'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getBillingInfo, getBusinessProfile } from '@/api/profile'
import { ProfileBusinessType } from '@/api/business/schema'
import { SUBSCRIPTION_TYPES, USER_STATUS } from '@/constants'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Spinner from '@/components/Spinner'
import { useToast } from '@/hooks/useToast'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { BillingInfoType } from '@/api/profile/schema'
import { cn } from '@/utils/helper'

type CancelRenewalArgs = {
    businessProfileData: ProfileBusinessType
    profileId: string
}

type CancelRenewalCtx = {
    previousData: ProfileBusinessType | undefined
}

const SubscriptionSettings = () => {
    const { toast } = useToast()
    const user = useViewedAsUser()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { profileId = '' } = user

    const {
        data: businessProfileData,
        isError,
        error,
    } = useQuery({
        enabled: !!profileId,
        queryKey: ['businessProfile', profileId],
        queryFn: () => getBusinessProfile(profileId),
    })

    const { data: billingInfoData } = useQuery<unknown, AxiosError, BillingInfoType>({
        queryKey: ['billingInfo'],
        queryFn: () => getBillingInfo(profileId as string),
        enabled: !!profileId,
    })

    const { mutate: cancelRenewalMu, isPending } = useMutation<
        string,
        AxiosError,
        CancelRenewalArgs,
        CancelRenewalCtx
    >({
        mutationFn: (params) => updateOwnerSubscriptionStatus(params.businessProfileData),
        onMutate: async ({ profileId, businessProfileData }) => {
            await queryClient.cancelQueries({
                queryKey: ['businessProfile', profileId],
            })

            const previousBusinessProfile = queryClient.getQueryData<ProfileBusinessType>([
                'businessProfile',
                profileId,
            ])

            queryClient.setQueryData(['businessProfile', profileId], businessProfileData)

            return { previousData: previousBusinessProfile }
        },
        onSuccess: (description, variables) => {
            queryClient.invalidateQueries({ queryKey: ['businessProfile', variables.profileId] })
            toast({ description })
        },
        onError: (err, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData(
                    ['businessProfile', variables.profileId],
                    context.previousData,
                )
            }
            toast({
                description: err.message,
                variant: 'destructive',
            })
        },
    })

    if (businessProfileData) {
        return (
            <section>
                {businessProfileData?.business.isDowngrade && (
                    <div className=' bg-zentive-bg-200 rounded-sm flex justify-between items-center px-[15px] mt-[24px] py-4'>
                        <p>
                            Downgrade Status: <span className='font-semibold'>Pending</span> <br />
                            Current Expiry Date:{' '}
                            {dayjs(businessProfileData?.business.subscriptionExpiryDate).format(
                                'MMMM DD, YYYY',
                            )}
                            <br />
                            In case you change your mind please cancel downgrade before <br />
                            {dayjs(businessProfileData?.business.subscriptionExpiryDate).format(
                                'MMMM DD, YYYY',
                            )}{' '}
                            to continue using our service.
                        </p>
                        <button>
                            <p
                                className='text-zentive-green-dark font-semibold mt-[16px] mb-[17px]  hover:cursor-pointer '
                                onClick={() => {
                                    navigate('/owner-subscription/cancel-downgrade')
                                }}
                            >
                                Cancel Downgrade
                            </p>
                        </button>
                    </div>
                )}

                {businessProfileData?.business?.subscriptionType &&
                    businessProfileData?.business?.subscriptionType.name !==
                        SUBSCRIPTION_TYPES.FREE_TRIAL && (
                        <>
                            <p className='mt-5 text-zentive-gray-medium font-bold'>
                                Subscription Settings
                            </p>
                            <div className='bg-white rounded-md ring ring-zentive-gray-light ring-opacity-30'>
                                <hr className='mx-5'></hr>
                                <div
                                    className={cn(
                                        'flex justify-between items-center px-[15px] w-full',
                                        billingInfoData?.lastFourNo ===
                                            'No Payment Method Provided.'
                                            ? 'py-4'
                                            : 'py-0',
                                    )}
                                >
                                    <p>Renewal</p>
                                    <AlertDialog>
                                        <AlertDialogTrigger>
                                            <button
                                                disabled={
                                                    businessProfileData?.business?.status ===
                                                    USER_STATUS.TERMINATED
                                                }
                                                hidden={
                                                    billingInfoData?.lastFourNo ===
                                                    'No Payment Method Provided.'
                                                }
                                            >
                                                <p
                                                    className='text-zentive-red-dark mt-[16px] mb-[17px]  hover:cursor-pointer'
                                                    hidden={
                                                        billingInfoData?.lastFourNo ===
                                                        'No Payment Method Provided.'
                                                    }
                                                >
                                                    {isPending && (
                                                        <Spinner size='5' variant='normal' />
                                                    )}
                                                    {!isPending &&
                                                        businessProfileData?.business?.status ===
                                                            USER_STATUS.ACTIVATED &&
                                                        billingInfoData &&
                                                        'Cancel Renewal'}
                                                    {!isPending &&
                                                        businessProfileData?.business?.status ===
                                                            USER_STATUS.BILLING_PAUSED &&
                                                        'Renew Subscription'}
                                                </p>
                                            </button>
                                        </AlertDialogTrigger>
                                        <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                                            <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                                                <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                                                    <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                                                </div>
                                                <div className=' overflow-auto'>
                                                    <AlertDialogTitle className='text-left font-semibold text-2xl text-[#191A0A] mt-[10px]'>
                                                        {businessProfileData?.business?.status ===
                                                        USER_STATUS.ACTIVATED
                                                            ? 'Cancel Renewal'
                                                            : 'Renew Subscription'}
                                                    </AlertDialogTitle>
                                                    <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                                                        By cancelling your renewal you will still
                                                        have access to the subscription service
                                                        until the current subscription period
                                                        expires, but will lose all access
                                                        thereafter.
                                                    </AlertDialogDescription>
                                                    <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                                                        Are you sure you want to cancel your
                                                        renewal?
                                                    </AlertDialogDescription>
                                                </div>
                                            </div>
                                            <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                                                <div className='w-full flex justify-end items-end gap-[16px]'>
                                                    <AlertDialogCancel className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                                                        Cancel
                                                    </AlertDialogCancel>
                                                    <AlertDialogAction
                                                        className='h-[43px] text-base font-semibold bg-zentive-green-dark'
                                                        onClick={() =>
                                                            cancelRenewalMu({
                                                                profileId,
                                                                businessProfileData,
                                                            })
                                                        }
                                                        disabled={isPending}
                                                    >
                                                        {businessProfileData?.business?.status ===
                                                        USER_STATUS.ACTIVATED
                                                            ? 'Cancel Renewal'
                                                            : 'Renew Subscription'}
                                                    </AlertDialogAction>
                                                </div>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>
                                </div>
                            </div>
                        </>
                    )}
            </section>
        )
    }

    if (isError) {
        return (
            <p className='text-[16px] text-zentive-red-dark font-semibold'>
                {error?.message || 'An error occured'}
            </p>
        )
    }

    return <Spinner size='50' variant='normal' />
}

export default SubscriptionSettings
