import { BulkProfileUpdateStatusType, FullProfileType } from '@/api/profile/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { bulkProfileStatusUpdate } from '@/api/profile'
import { useToast } from '@/hooks/useToast'
import { Dispatch, SetStateAction, useState } from 'react'
import { USER_STATUS } from '@/constants'
import DeleteProfileJobRestrictionModal from '@/components/DeleteProfileJobRestrictionModal/indexs'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'

interface RestoreModalProps {
    profilesToUpdate: BulkProfileUpdateStatusType
    onSuccessRestore: Dispatch<SetStateAction<BulkProfileUpdateStatusType>>
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    selected: string
}

const DeleteModal: React.FC<RestoreModalProps> = ({
    profilesToUpdate,
    onSuccessRestore,
    open,
    setOpen,
    selected,
}) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const [openRestrictionModal, setOpenRestrictionModal] = useState<boolean>(false)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    if (selected == USER_STATUS.ACTIVATED) {
        profilesToUpdate.status = USER_STATUS.INACTIVE
    } else {
        profilesToUpdate.status = USER_STATUS.ACTIVATED
    }
    profilesToUpdate.businessId = user?.businessId
    const { mutate: deleteProfilesMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: BulkProfileUpdateStatusType) => bulkProfileStatusUpdate(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['Customers'] })
            toast({
                description: profilesToUpdate.status == USER_STATUS.ACTIVATED ? "Successfully activated" : "Successfully deactivated",
                variant: 'default',
            }),
                onSuccessRestore({
                    profileIds: [],
                })
        },
        onError: () => setOpenRestrictionModal(true),
    })

    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
            {selected == USER_STATUS.ACTIVATED ? (
                <DeleteProfileJobRestrictionModal
                    open={openRestrictionModal}
                    setOpen={setOpenRestrictionModal}
                    type='customer'
                />
            ) : (
                <DeleteProfileJobRestrictionModal
                    open={false}
                    setOpen={setOpenRestrictionModal}
                    type='customer'
                />
            )}
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Change Account Status
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            {selected == USER_STATUS.ACTIVATED
                                ? `This action is set the account to inactive. You can always activate the account
                                on the inactive tab anytime.`
                                : `This action is set the account to an active one. You can always deactivate the account
                                on the active tab anytime.`}
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            {`Are you sure you want to ${
                                selected == USER_STATUS.ACTIVATED ? 'deactivate' : 'activate'
                            } this account?`}
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-[15px] pr-6 pb-4 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-4'>
                        <AlertDialogCancel className='w-97 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={() => {
                                deleteProfilesMu(profilesToUpdate)
                            }}
                            className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                        >
                            {`${selected == USER_STATUS.ACTIVATED ? 'Deactivate' : 'Activate'}`}
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteModal
