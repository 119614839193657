import { Card, CardContent, CardFooter } from '@/components/Card'
import { FaInfoCircle } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { getInvoiceTotalOwed, updateCustomerAutoPay } from '@/api/customer'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CustomerAutoPayType, InvoiceOwedType } from '@/api/customer/schema'
import { getBillingInfo, getBusinessProfile } from '@/api/profile'
import { ProfileSelfResponseType } from '@/api/business/schema'
import { BillingInfoType } from '@/api/profile/schema'
import { useEffect, useState } from 'react'
import { BREADCRUMBS_PADDING_STYLE, MOBILE_MAX_WIDTH, USER_STATUS } from '@/constants'
import { useToast } from '@/hooks/useToast'
import { Label } from '@radix-ui/react-label'
import { cn } from '@/utils/helper'
import BreadCrumbs from '@/components/BreadCrumbs'
import dayjs from 'dayjs'
import Tooltip from '@/components/Tooltip'
import { useAtomValue } from 'jotai'
import { businessIdAtom } from '@/store/auth'
import { useMediaQuery } from 'react-responsive'

const PaymentContent = () => {
    const user = JSON.parse(localStorage.getItem('user') as string)
    const { toast } = useToast()
    const [isAutoPay, setIsAutoPay] = useState<boolean>(false)
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH })

    const businessId = useAtomValue(businessIdAtom)

    const { data: invoiceOwed } = useQuery<unknown, AxiosError, InvoiceOwedType>({
        queryKey: ['invoiceOwed', businessId, user.profileId],
        queryFn: () => getInvoiceTotalOwed(user?.profileId as string, businessId as string),
        enabled: businessId != undefined,
    })

    const { data: customerProfileData } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        queryKey: ['businessProfile'],
        queryFn: () => getBusinessProfile(user?.profileId as string),
    })

    useEffect(() => {
        setIsAutoPay(customerProfileData?.isAutoPay as boolean)
    }, [customerProfileData])

    const { data: billingInfoData } = useQuery<unknown, AxiosError, BillingInfoType>({
        queryKey: ['billingInfo'],
        queryFn: () => getBillingInfo(user.profileId as string),
        enabled: !!user.profileId,
    })

    const { mutate: UpdateAutoPayMu } = useMutation<unknown, AxiosError, CustomerAutoPayType>({
        //TODO: Refresh the data when toggle to true
        mutationFn: async (updateAutoPayData: CustomerAutoPayType) =>
            updateCustomerAutoPay(updateAutoPayData),
        onSuccess: () => {
            const toastMessage = isAutoPay
                ? 'Autopay enabled successfully'
                : 'Autopay disabled successfully'
            toast({
                description: toastMessage,
                variant: 'default',
            })
        },
    })

    const handleChange = (bool: boolean) => {
        setIsAutoPay(bool)
        UpdateAutoPayMu({
            profileId: user.profileId as string,
            isAutoPay: bool,
            invoiceOwed: {
                profileID: invoiceOwed?.profileID,
                customerID: invoiceOwed?.customerID,
                amount: invoiceOwed?.amount,
                description: 'multiple',
            },
        } as CustomerAutoPayType)
    }

    useEffect(() => {
        if (isMobile) {
            toast({
                description:
                    'For better user experience, please turn your mobile phone to landscape mode or use tablet/laptop.',
                variant: 'customer_interface',
                duration: 20000,
            })
        }
    }, [])

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Payments' />
            <Card className='mt-5 w-full p-6'>
                <CardContent>
                    <div className='space-y-4'>
                        <div className='text-md  mx-3 font-semibold text-zentive-gray-medium'>
                            Account Overview
                        </div>
                        <Card className={cn('mx-3 !mt-2')}>
                            <CardContent className='p-2 py-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-[#191A0A] text-base'>
                                <div className='flex flex-col space-y-1 mx-1'>
                                    <p className='font-semibold'>Active Since</p>
                                    <p className='text-gray-500'>
                                        {customerProfileData &&
                                            dayjs(customerProfileData.createdAt).format(
                                                'MMMM DD, YYYY',
                                            )}
                                    </p>
                                </div>
                                <div className='flex flex-col  space-y-1 items-center w-full '>
                                    <div className='flex-col flex  space-y-1 mr-[80px]'>
                                        <p className='font-semibold text-left'>Active Status</p>
                                        <p className='text-gray-500 text-left'>
                                            {customerProfileData?.status == USER_STATUS.ACTIVATED
                                                ? 'Active'
                                                : 'Inactive'}
                                        </p>
                                    </div>
                                </div>
                                <div className='flex flex-col items-end space-y-1 w-full '>
                                    <div className='flex-col flex  space-y-2 mr-[80px]'>
                                        <div className='flex-col flex  space-y-0 mb-3'>
                                            <p className='font-semibold text-left'>
                                                Total Amount Owed
                                            </p>
                                            <p className='text-indigo-900 font-semibold text-2xl text-left'>
                                                $
                                                {invoiceOwed?.amount == null
                                                    ? 0
                                                    : invoiceOwed?.amount.toLocaleString(
                                                          undefined,
                                                          {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2,
                                                          },
                                                      )}
                                            </p>
                                        </div>
                                        {billingInfoData?.lastFourNo ===
                                            'No Payment Method Provided.' ||
                                        invoiceOwed?.amount == null ? (
                                            <Label
                                                className={'text-gray-500'}
                                                onClick={() => {
                                                    if (
                                                        billingInfoData?.lastFourNo ===
                                                        'No Payment Method Provided.'
                                                    ) {
                                                        toast({
                                                            description:
                                                                'Unable to pay now please set up your payment method first',
                                                        })
                                                    }
                                                }}
                                            >
                                                {' '}
                                                Pay Now
                                            </Label>
                                        ) : invoiceOwed?.amount == 0 ? (
                                            <Label className={'text-zentive-gray-dark text-left'}>
                                                Pay Now
                                            </Label>
                                        ) : (
                                            <NavLink
                                                to={`/customer-portal/invoice-payment`}
                                                className={'text-zentive-green-dark text-left'}
                                            >
                                                Pay Now
                                            </NavLink>
                                        )}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div className='text-md pt-5 mx-3 font-semibold text-zentive-gray-medium'>
                            Billing Information
                        </div>
                        <Card className={cn('mx-3 !mt-2')}>
                            <CardContent className='px-2 py-5 grid grid-cols-1 lg:grid-cols-2 gap-4 text-[#191A0A] text-base mx-1'>
                                <div className='flex flex-col space-y-1 '>
                                    <p className='font-semibold'>
                                        {customerProfileData?.firstName +
                                            ' ' +
                                            customerProfileData?.lastName}
                                    </p>
                                    <p className='text-gray-500'>{customerProfileData?.email}</p>
                                </div>

                                <div className='flex flex-col grid-cols-1 space-y-2 items-end mr-5'>
                                    <NavLink
                                        to='/customer-portal/payments/billing-info'
                                        className={'text-zentive-green-dark text-right my-auto'}
                                    >
                                        Update Billing Information
                                    </NavLink>
                                </div>
                            </CardContent>
                        </Card>
                        <div className='text-md pt-5 mx-3 font-semibold text-zentive-gray-medium'>
                            Payment Information
                        </div>
                        <Card className={cn('mx-3 !mt-2')}>
                            <CardContent className='px-2 py-5 grid grid-cols-1 lg:grid-cols-2 gap-4 text-[#191A0A] text-base mx-1 mb-5'>
                                <div className='flex flex-col space-y-1'>
                                    <p className=''>{billingInfoData?.lastFourNo}</p>
                                </div>

                                <div className='flex flex-col  space-y-2 mr-5 lg:items-end'>
                                    <NavLink
                                        to='/customer-portal/payments/customer-change-card'
                                        className={'text-zentive-green-dark my-auto'}
                                    >
                                        Update Payment Details
                                    </NavLink>
                                </div>
                            </CardContent>
                            <CardFooter className='rounded-b-md bg-[#EBEBEB] px-2'>
                                <div className='flex space-x-4 mt-[24px] font-semibold text-left mx-1'>
                                    <input
                                        type='checkbox'
                                        className='my-auto text-zentive-green-dark'
                                        checked={isAutoPay}
                                        onChange={
                                            billingInfoData?.lastFourNo ===
                                            'No Payment Method Provided.'
                                                ? () => {
                                                      toast({
                                                          description:
                                                              'Unable to change auto pay please set up your payment method first',
                                                      })
                                                  }
                                                : (event) => handleChange(event.target.checked)
                                        }
                                    />
                                    <p className='text-left my-auto'>Enable Autopay</p>
                                    <div className='flex my-auto !ml-2'>
                                        <Tooltip
                                            position='bottom'
                                            alignment='start'
                                            width='80'
                                            fontstyle='font-sans'
                                            content='By turning this on payments are automatically deducted from your bank account 
                                            or charged to your credit card without requiring manual intervention each time.'
                                        >
                                            <FaInfoCircle className='mb-1 mt-1' />
                                        </Tooltip>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
export default PaymentContent
