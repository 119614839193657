import { Card, CardContent, CardFooter } from '@/components/Card'
import { useForm } from 'react-hook-form'
import { Form } from '@/components/Forms'
import { Button } from '@/components/Button'
import Fields from './Fields'
import { RichTextEditorWrapper } from './RichTextEditor'
import {
    EmailRequestServiceParamType,
    RequestServiceType,
    emailRequestServiceParamSchema,
} from '@/api/service/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { sendEmailRequest } from '@/api/service'
import { toast } from '@/hooks/useToast'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { E164Number } from 'libphonenumber-js'
import { formatPhoneNumber } from 'react-phone-number-input'

interface RequestServiceFormProps {
    services: RequestServiceType
}

const RequestServiceForm: React.FC<RequestServiceFormProps> = ({ services }) => {
    const navigate = useNavigate()
    const user = useViewedAsUser()

    const methods = useForm<EmailRequestServiceParamType>({
        mode: 'onSubmit',
        resolver: zodResolver(emailRequestServiceParamSchema),
    })

    const { watch } = methods
    const { mutate: sendEmailMu } = useMutation<
        ResponseType,
        AxiosError,
        EmailRequestServiceParamType
    >({
        mutationKey: ['SendMessage'],
        mutationFn: (data) => sendEmailRequest(data),
        onSuccess: () => {
            toast({
                description: 'Request Sent!',
                variant: 'default',
            })
            navigate('/customer-portal/dashboard')
        },
    })

    const onSubmit = (data: EmailRequestServiceParamType) => {
        sendEmailMu(data)
    }

    const isBodyEmpty = (body: string) => {
        if (body) {
            const strippedContent = body
                .replace(/<p>(\s|&nbsp;)*<\/p>/g, '')
                .replace(/<br\s*\/?>/g, '')
                .trim()

            if (strippedContent === `""`) {
                return false
            } else {
                return true
            }
        }
    }

    useEffect(() => {
        methods.setValue('customerEmail', user?.email)
        methods.setValue('customerName', `${user?.firstName} ${user?.lastName}`)
        methods.setValue('ownerName', `${services?.firstName} ${services?.lastName}`)
        methods.setValue('ownerEmail', services?.email)
        methods.setValue('phoneNumber', formatPhoneNumber(user?.phoneNumber as E164Number))
        methods.setValue(
            'address',
            `${services?.streetAddress}, ${services?.city}, ${services?.state} ${services?.zipCode}`,
        )
    }, [services])

    return (
        <Form {...methods}>
            <Card className='w-[50%] lg:max-w-[420px] xl:max-w-[490px] 2xl:max-w-[746px]'>
                <CardContent className='p-4 pt-[36px] space-y-8'>
                    <Fields services={services} />
                    <RichTextEditorWrapper />
                </CardContent>
                <CardFooter className='p-4 mt-[20px] lg:flex lg:justify-end rounded-b-md bg-zentive-gray-light'>
                    <div className='flex space-x-4 my-auto'>
                        <Button
                            onClick={methods.handleSubmit(onSubmit)}
                            type='submit'
                            className='w-full'
                            disabled={!isBodyEmpty(watch().body as string)}
                        >
                            Submit
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </Form>
    )
}
export default RequestServiceForm
