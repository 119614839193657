import { Checkbox } from '@/components/Checkbox'
import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { manageSubsTabAtom, toExportSubsAtom } from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { useAtom, useAtomValue } from 'jotai'
import { FC } from 'react'
import { SubscriberProps } from '../..'

const T_HEADER_CLASS = 'font-semibold text-zentive-gray-medium text-base'

const SubsribersTableHead: FC<SubscriberProps> = ({ subscriberList }) => {
    const subStatus = useAtomValue(manageSubsTabAtom)

    const [toexportBusinessData, setToExportBusinessData] = useAtom(toExportSubsAtom)

    const handleSelectAllSubs = (isChecked: boolean) =>
        setToExportBusinessData(isChecked ? subscriberList : [])

    return (
        <TableHeader className='bg-white'>
            <TableRow className='whitespace-nowrap'>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex items-center text-start gap-[10px]'>
                        <Checkbox
                            key={subStatus}
                            id='business-name'
                            className='mr-[5px] mt-[2px]'
                            onCheckedChange={(val) => handleSelectAllSubs(val as boolean)}
                            checked={
                                toexportBusinessData?.length
                                    ? toexportBusinessData?.length > 0 &&
                                      toexportBusinessData?.length == subscriberList?.length
                                    : false
                            }
                        />
                        Business Name
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Subscriber
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center  text-end gap-[10px]'>
                        Subscription
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center  text-end gap-[10px]'>
                        Billing Cycle
                    </div>
                </TableHead>
                {subStatus === 'TER' && (
                    <TableHead
                        key='contacted'
                        className={cn(T_HEADER_CLASS, 'w-12 px-2 text-left')}
                    >
                        Contacted
                    </TableHead>
                )}
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Email Address
                    </div>
                </TableHead>

                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Address
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Contact Number
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Registered On
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>Note</TableHead>
            </TableRow>
        </TableHeader>
    )
}

export default SubsribersTableHead
