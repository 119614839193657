import { useRef } from 'react'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { AddBulkLeadsType, InviteLeadsViaCSVType } from '@/api/lead/schema'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addBulkLeads } from '@/api/lead'
import { useToast } from '@/hooks/useToast'

const ImportLeads = () => {
    const currentUser = useViewedAsUser()
    const inputFileRef = useRef<HTMLInputElement | null>(null)
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { mutate: addBulkLeadsMu } = useMutation<
        InviteLeadsViaCSVType,
        AxiosError,
        AddBulkLeadsType
    >({
        mutationFn: addBulkLeads,
        onSuccess: (data) => {
            if (data.failedImports.length <= 0) {
                queryClient.invalidateQueries({ queryKey: ['leadList'] })
                toast({
                    description: 'Leads profiles has been added successfully',
                    variant: 'default'
                })
            } else {
                toast({
                    description: `Something went wrong, must have unique Email, Phone Number and Deal Stage Label and Product Service Types must be existing from the list..`,
                    variant: 'destructive',
                })
            }
        },
        onError: () => {
            if (inputFileRef.current) {
                inputFileRef.current.value = ''
            }
        },
    })

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files && files.length > 0) {
            const file = files[0]
            addBulkLeadsMu({ businessId: currentUser?.businessId, leadsCSV: file })
        }
    }

    const handleClick = () => {
        inputFileRef.current?.click()
    }

    return (
        <>
            <input
                type='file'
                accept='.csv,.xlsx,.xls'
                ref={inputFileRef}
                onChange={handleFileInputChange}
                className='hidden'
            />
            <button
                onClick={() => handleClick()}
                className='relative flex w-full select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 cursor-pointer'
            >
                Import CSV
            </button>
        </>
    )
}

export default ImportLeads
