import { getAllCustomers } from '@/api/profile'
import { CustomerProfileListType, ProfileType } from '@/api/profile/schema'
import { QuoteReportFilterType } from '@/api/reports/schema'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import SearchBar from '@/components/InputSearchBox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { USER_STATUS } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { quoteReportFilterAtom } from '@/store/reports'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'

export const CustomerFilter = () => {
    const [open, setIsOpen] = useState<boolean>(false)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const [searchValue, setSearchValue] = useState('')
    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const [quoteReportFilter, setQuoteReportFilter] = useAtom(quoteReportFilterAtom)
    const [customerFilter, setCustomerFilter] = useState<QuoteReportFilterType | null>(null)

    const handleGetCheckState = (profileId: string) => {
        return customerFilter?.customerIds?.some((id) => profileId === id)
    }

    const { data: customers, isPending } = useQuery<unknown, AxiosError, CustomerProfileListType>({
        queryKey: ['invoiceReportCustomers', pagination, searchValue, user?.businessId],
        queryFn: () =>
            getAllCustomers(
                user?.businessId as string,
                USER_STATUS.ACTIVATED,
                searchValue,
                '',
                pagination,
            ),
    })

    const handleAddCustomerFilter = (profileId: string) => {
        setCustomerFilter((prevFilter) => {
            if (!prevFilter) {
                return {
                    customerIds: [profileId],
                    status: [],
                    start: '',
                    end: '',
                    keyword: '',
                }
            }

            const updatedProfileIds = prevFilter.customerIds?.includes(profileId)
                ? prevFilter.customerIds.filter((id) => id !== profileId)
                : [...(prevFilter.customerIds || []), profileId]

            return {
                ...prevFilter,
                customerIds: updatedProfileIds,
            }
        })
    }

    const handleSaveCustomerFilter = () => {
        setQuoteReportFilter((prev) => {
            if (!customerFilter) {
                return prev
            }

            const customerProfileIds = customerFilter.customerIds || []
            const quoteReportProfileIds = prev?.customerIds || []
            const keyword = prev?.keyword || ''
            const status = prev?.status || []
            const start = prev?.start || ''
            const end = prev?.end || ''

            const filteredProfileIds = quoteReportProfileIds.filter((id) =>
                customerProfileIds.includes(id),
            )

            const updatedProfileIds = [
                ...filteredProfileIds,
                ...customerProfileIds.filter((id) => !filteredProfileIds.includes(id)),
            ]

            return {
                ...prev,
                customerIds: updatedProfileIds,
                keyword,
                status,
                start,
                end,
            }
        })

        setIsOpen(false)
    }

    const handleClearFilters = () => {
        setQuoteReportFilter({
            customerIds: [],
            keyword: quoteReportFilter?.keyword ?? '',
            status: [],
            start: '',
            end: '',
        })

        setIsOpen(false)
    }

    useEffect(() => {
        setSearchValue('')
        if (open) {
            setCustomerFilter({
                customerIds: quoteReportFilter?.customerIds || [],
                status: quoteReportFilter?.status || [],
                start: quoteReportFilter?.start || '',
                end: quoteReportFilter?.end || '',
                keyword: quoteReportFilter?.keyword ?? '',
            })
        }
    }, [open])

    return (
        <AlertDialog open={open} onOpenChange={setIsOpen} key={'customer-filter'}>
            <AlertDialogTrigger asChild onClick={() => {}}>
                <button type='button' className='button-outline'>
                    Customer Filter
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white rounded-[15px] p-0 max-w-[38rem]'>
                <div className='flex flex-col gap-5 px-16'>
                    <AlertDialogTitle className='text-left font-semibold text-lg text-zentive-black py-10 pb-0'>
                        <h1 className='text-2xl font-bold text-left'>Filter by Customer</h1>
                    </AlertDialogTitle>
                    <SearchBar
                        onSearchChange={(e) => setSearchValue(e.target.value)}
                        placeHolder='Search For...'
                    />

                    <div className='p-5 flex flex-row border border-1 border-zentive-gray-light'>
                        <RadioGroup>
                            {customers && !isPending ? (
                                customers?.content?.map((c: ProfileType) => (
                                    <div
                                        className='flex flex-row gap-2 items-center'
                                        key={c.profileId}
                                    >
                                        <RadioGroupItem
                                            checked={handleGetCheckState(c.profileId)}
                                            value={c.profileId}
                                            onClick={() => handleAddCustomerFilter(c.profileId)}
                                            className='text-zentive-gray-medium'
                                            key={c.profileId}
                                        />
                                        <p>
                                            {c.firstName} {c.lastName}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <li>No Customers Yet</li>
                            )}
                        </RadioGroup>
                    </div>

                    <Pagination
                        itemsPerPage={pagination.pageSize ?? 5}
                        setPagination={setPagination}
                        totalRecords={customers?.meta?.totalRecords ?? 1}
                        pagination={pagination}
                    />
                </div>

                <AlertDialogFooter className='py-5 w-full rounded-b-md items-center justify-center bg-zentive-gray-light max-w-[38rem]'>
                    <div className='flex flex-row gap-2'>
                        <Button
                            type='button'
                            variant='link'
                            className='w-[8rem] font-semibold text-zentive-blue-dark text-base'
                            onClick={handleClearFilters}
                        >
                            Clear Filters
                        </Button>
                        <div className='flex flex-row gap-5 mr-5'>
                            <Button
                                type='button'
                                variant='outline'
                                className='w-[11rem]'
                                onClick={() => {
                                    setIsOpen(!open)
                                    setCustomerFilter(null)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='button'
                                disabled={!customerFilter}
                                className={`w-[11rem]`}
                                onClick={handleSaveCustomerFilter}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
