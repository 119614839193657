import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile } from '@/api/profile'
import { updateSubscription } from '@/api/subscription'
import { SubscriptionType, UpdateSubscriptionType } from '@/api/subscription/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { cn } from '@/utils/helper'
import { AlertDialogDescription, AlertDialogTitle } from '@radix-ui/react-alert-dialog'
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
interface ChangePlanButtonProps {
    plan: SubscriptionType
    changePlanType: string
}

const ChangePlanButton = ({ plan, changePlanType }: ChangePlanButtonProps) => {
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)
    const profileID = params.get('profileID')
    const subscriptionTypeId = params.get('subscriptionTypeId')

    const queryClient = new QueryClient()

    const { mutate: updateSubscriptionMu, isPending: updateLoading } = useMutation<
        unknown,
        AxiosError,
        UpdateSubscriptionType
    >({
        mutationFn: (data) => updateSubscription(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['businessProfile'] })
            queryClient.invalidateQueries({ queryKey: ['Plan'] })
            queryClient.invalidateQueries({ queryKey: ['subsribersList'] })
            navigate('/manage-subs/subscribers')
        },
        onError: (err) => console.log(err),
    })

    const { data: businessProfileData } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        queryKey: ['businessProfile'],
        queryFn: () => getBusinessProfile(profileID as string), // replace this once getAllSubs is already integrated
    })

    return (
        <AlertDialog key={plan.name}>
            <AlertDialogTrigger disabled={plan.subscriptionTypeId == subscriptionTypeId}>
                <Button
                    disabled={plan.subscriptionTypeId == subscriptionTypeId}
                    key={plan.name}
                    className={cn(
                        changePlanType === 'Current Plan' ? 'bg-zentive-gray-medium' : '',
                        'w-[160px]',
                    )}
                    type='submit'
                >
                    {changePlanType == 'Current Plan' ? (
                        <div>
                            <p className='text-base'>{plan.name}</p>
                        </div>
                    ) : (
                        <div>
                            <p className='text-sm'>{changePlanType}</p>
                            <p className='text-base'>{plan.name}</p>
                        </div>
                    )}
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-zentive-black mt-[10px]'>
                            Change Plan
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            {!businessProfileData?.business?.isChangePlan ? (
                                <>
                                    <p>
                                        Changing the plan will affect the access to features of the
                                        subscriber?
                                    </p>
                                    <p className='my-4'> Are you sure you want to change plan? </p>
                                </>
                            ) : (
                                <>
                                    <p className='my-4'>
                                        Changing of plan is currently not available since the
                                        downgrade is curently in process.
                                    </p>
                                </>
                            )}
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            {businessProfileData?.business.isChangePlan ? 'Back' : 'Cancel'}
                        </AlertDialogCancel>
                        {businessProfileData?.business.isChangePlan ? null : (
                            <AlertDialogAction
                                disabled={updateLoading}
                                onClick={() => {
                                    if (changePlanType !== 'Current Plan') {
                                        updateSubscriptionMu({
                                            userId: profileID as string,
                                            planId: plan.subscriptionTypeId as string,
                                        } as UpdateSubscriptionType)
                                    }
                                }}
                                className='w-97 h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                            >
                                Change Plan
                            </AlertDialogAction>
                        )}
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default ChangePlanButton
