import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import { FaPencilAlt } from 'react-icons/fa'
import { ProductServiceType } from '@/api/service/schema'
import { productServiceSchema } from '@/api/quoting/schema'
import { updateService } from '@/api/service'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from '@/components/Forms'
import { UpdateInputForm } from './UpdateFormService'
import _ from 'lodash'
import { Button } from '@/components/Button'

type UpdateServiceProps = {
    service: ProductServiceType
}

const UpdateServiceModal = (props: UpdateServiceProps) => {
    const { toast } = useToast()
    const [open, setOpen] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const methods = useForm<ProductServiceType>({
        defaultValues: props.service,
        mode: 'onChange',
        resolver: zodResolver(productServiceSchema),
    })

    const { reset, watch } = methods

    const formValues = watch()

    const initialFormValues = useMemo(() => ({ ...props.service }), [props.service])

    const isFormDirty = useCallback(
        () => !_.isEqual(initialFormValues, formValues),
        [initialFormValues, formValues],
    )

    const { mutate: updateServiceMu, isPending: updateLoading } = useMutation<
        unknown,
        AxiosError,
        ProductServiceType
    >({
        mutationFn: updateService,
        onSuccess: () => {
            toast({ description: 'Successfully Updated' })
            queryClient.invalidateQueries({ queryKey: ['productServiceList'] })
            setOpen(false)
        },
        onError: () => {
            toast({
                description: 'Product/Service already exists',
                variant: 'destructive',
            })
        },
    })

    useEffect(() => {
        if (open) {
            reset(props.service)
        }
    }, [open, props.service])

    const onSubmit = (data: ProductServiceType) => updateServiceMu(data)

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
                <button key='pencil-icon' type='button'>
                    <FaPencilAlt className='h-4 w-4 cursor-pointer text-indigo-900' />
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-fit min-w-[35rem] rounded-[15px] p-0 '>
                <Form {...methods}>
                    <form>
                        <UpdateInputForm />

                        <AlertDialogFooter className='bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px] -mt-10'>
                            <div className='w-full flex justify-end items-end gap-[16px]'>
                                <AlertDialogCancel
                                    className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                                    disabled={updateLoading}
                                >
                                    Cancel
                                </AlertDialogCancel>
                                <Button
                                    className='w-97 h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                                    disabled={updateLoading || !isFormDirty()}
                                    onClick={methods.handleSubmit(onSubmit)}
                                    type='submit'
                                >
                                    Update
                                </Button>
                            </div>
                        </AlertDialogFooter>
                    </form>
                </Form>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default UpdateServiceModal
