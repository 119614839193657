import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Label } from '@/components/Label'
import { USER_STATUS } from '@/constants'
import { useFormContext } from 'react-hook-form'

const EmployeeCompensationFields = () => {
    const { control, getValues} = useFormContext()
    const status = getValues('status')
    const isAccountInactive = status === USER_STATUS.INACTIVE

    const handleChange = (val: string, onChange: (val: number | string | undefined) => void) => {
        if (val === '') {
            onChange(undefined)
        } else {
            const numValue = parseFloat(val);
            if (!isNaN(numValue)) {
                const formattedValue = parseFloat(numValue.toFixed(2))
                onChange(formattedValue)
            }
        }
    }

    return (
        <div>
            <div className='mt-[14px] text-left'>
                <div className='w-[932px] h-[45px]'>
                    <Label className='font-semibold text-base text-zentive-green-dark'>
                        Hourly Rate
                    </Label>
                </div>
            </div>
            <div className='grid grid-cols-3 sm:grid-cols-1 gap-4'>
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='compensation'
                        disabled={isAccountInactive}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 w-full h-[45px] border border-black text-zentive-black disabled:bg-[#EBEBEB] rounded-sm'
                                        type='number'
                                        placeholder='Pay Rate Per Hour*'
                                        {...field}
                                        onChange={(evt) => handleChange(evt.target.value, field.onChange)}
                                        value={field.value || ''}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='commission'
                        disabled={isAccountInactive}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 w-full h-[45px] border border-black text-zentive-black disabled:bg-[#EBEBEB] rounded-sm'
                                        type='number'
                                        placeholder='Commission (Optional)'
                                        {...field}
                                        onChange={(evt) => handleChange(evt.target.value, field.onChange)}
                                        value={field.value || 0}
                                        onKeyDown={(e) => {
                                            if (
                                                e.key === '-' ||
                                                e.key === '+' ||
                                                e.key === 'e' ||
                                                e.key === 'E'
                                            ) {
                                                e.preventDefault()
                                            }
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmployeeCompensationFields
