import { getJobsByCustomerProfileID } from '@/api/job'
import { FullProfileType } from '@/api/profile/schema'
import { Card, CardContent, CardFooter, CardHeader } from '@/components/Card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { COMPLETE_FORMAT_WITHOUT_TIME } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { cn, getQuoteServiceNames } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { useParams } from 'react-router-dom'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { useEffect } from 'react'
import { jobPaginationAtom } from '@/store/job'
import { Pagination } from '@/components/Pagination'
import { workingHistorySortByAtom, workingHistorySortOrderAtom } from '@/store/customer'

const tableHeader = ['Date', 'Service', 'Description', 'Charge', 'Balance', 'Payment Method']

const WorkOrServiceHIstory = () => {
    const { profileId } = useParams()
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const [pagination, setPagination] = useAtom(jobPaginationAtom)
    const [sortBy, setSortBy] = useAtom(workingHistorySortByAtom)
    const [sortOrder, setSortOrder] = useAtom(workingHistorySortOrderAtom)

    const { data: workHistory } = useQuery({
        enabled: !!profileId,
        queryKey: ['customerJobs', profileId, user.businessId, pagination, sortBy, sortOrder],
        queryFn: () =>
            getJobsByCustomerProfileID(profileId as string, user.businessId as string, pagination, {
                sortBy: sortBy,
                sortOrder: sortOrder,
            }),
    })

    const onHandleSorting = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setSortBy(column)
            setSortOrder('ASC')
        }
    }

    const getSortIcon = (column: string) => {
        if (sortBy === column) {
            return sortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    useEffect(() => {
        setPagination({
            page: 1,
            pageSize: 5,
        })
    }, [setPagination])

    return (
        <Card className='w-full'>
            <CardHeader></CardHeader>
            <CardContent className='overflow-x-scroll md:overflow-x-auto'>
                <Table>
                    <TableHeader>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={`${header}-head`}
                                    className={cn(
                                        'flex-row items-center text-center font-semibold text-zentive-gray-medium text-base',
                                        header === 'Date' || header === 'Service'
                                            ? 'items-start text-start'
                                            : 'items-center text-center',
                                    )}
                                >
                                    <div className='inline-flex gap-[10px]'>
                                        {header}
                                        <button
                                            onClick={() => onHandleSorting(header)}
                                            className='ml-2'
                                            hidden={
                                                header === 'Balance' || header === 'Payment Method'
                                            }
                                        >
                                            {getSortIcon(header)}
                                        </button>
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {!!workHistory?.content?.length &&
                            workHistory?.content?.map((job, index) => (
                                <TableRow key={index} className='whitespace-nowrap'>
                                    <TableCell className='flex-row items-start text-start  text-[#191A0A] text-base'>
                                        {dayjs(job?.appointment?.startDateTime).format(
                                            COMPLETE_FORMAT_WITHOUT_TIME,
                                        )}
                                    </TableCell>
                                    <TableCell className='flex-row items-start text-start  text-[#191A0A] text-base'>
                                        {getQuoteServiceNames(job.quote.productServiceGroup)}
                                    </TableCell>
                                    <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base'>
                                        {job.description ?? '--'}
                                    </TableCell>
                                    <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base'>
                                        {`$${job?.quote?.totalAmount?.toFixed(2)}`}
                                    </TableCell>
                                    <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base'>
                                        {`$${
                                            job?.invoice
                                                ? job?.invoice?.totalAmount?.toFixed(2)
                                                : job?.quote?.totalAmount?.toFixed(2)
                                        }`}
                                    </TableCell>
                                    <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base'>
                                        Credit
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {!!workHistory?.content?.length && (
                    <div className='mb-5 mt-3'>
                        {pagination.pageSize !== undefined && (
                            <Pagination
                                itemsPerPage={pagination.pageSize}
                                setPagination={setPagination}
                                totalRecords={workHistory?.meta.totalRecords ?? 1}
                                pagination={pagination}
                            />
                        )}
                    </div>
                )}
                {!workHistory?.content?.length && (
                    <div className='mx-4 my-10'>
                        <div className='flex justify-center rounded-md bg-white'>
                            <img
                                src={emptyPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-8 h-60'
                            />
                        </div>
                        <p className='text-center text-2xl text-zentive-green-dark mt-4'>
                            {`No Work or Service History is Found`}
                        </p>
                        <p className='pb-4 text-center text-l text-zentive-gray-medium'>
                            {`You don't have any Work or Service History to display.`}
                        </p>
                    </div>
                )}
            </CardContent>
            <CardFooter className='items-end justify-end'></CardFooter>
        </Card>
    )
}
export default WorkOrServiceHIstory
