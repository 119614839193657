import React from 'react'
import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { EmployeeListType } from '@/api/employee/schema'
import { E164Number } from 'libphonenumber-js/core'
import { formatPhoneNumber } from 'react-phone-number-input'
interface IExportDataToCSV {
    employeeNumber: string
    name: string
    compensation: number
    commission: number
    caLicense: string
    email: string
    phoneNumber: string
    address: string
    dateOfBirth: string
    dateHired: string
}

const ExportEmployeeCSV = ({
    isOpen,
    setIsOpen,
    employeeListData,
}: {
    isOpen?: boolean
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
    employeeListData?: EmployeeListType
}) => {
    const tableData: IExportDataToCSV[] | null =
        (employeeListData &&
            (employeeListData.content?.map((employee) => {
                return {
                    employeeNumber: '000' + employee.employeeNumber,
                    name: employee.firstName + ' ' + employee.lastName,
                    compensation: employee.compensation,
                    commission: employee.commission,
                    caLicense: employee.caLicense,
                    email: employee.email,
                    phoneNumber: `${
                        employee.phoneNumber.startsWith('+1')
                            ? `(${employee.phoneNumber.substring(0, 2)})-${formatPhoneNumber(
                                  employee.phoneNumber as E164Number,
                              )
                                  .replace('0', '')
                                  .replace(' ', '-')}`
                            : `(${employee.phoneNumber.substring(0, 3)})-${formatPhoneNumber(
                                  employee.phoneNumber as E164Number,
                              )
                                  .replace('0', '')
                                  .replace(' ', '-')}`
                    }`,
                    address: `${employee?.address.streetAddress + ','} ${
                        employee?.address.streetTwoAddress !== undefined
                            ? employee?.address.streetTwoAddress + ', '
                            : ''
                    }${employee?.address.city + ','} ${employee?.address.state + ','} ${employee
                        ?.address.zipCode}`,
                    dateOfBirth: dayjs(employee.dateOfBirth).format('MMMM DD, YYYY'),
                    dateHired: dayjs(employee.createdAt).format('MMMM DD, YYYY'),
                }
            }) as IExportDataToCSV[])) ||
        null

    if (!tableData) return

    const csvHeaders = [
        'Employee Number',
        'Name',
        'Compensation',
        'Commission',
        'Chemical Applicator License',
        'Email Address',
        'Address',
        'Phone Number',
        'Date of Birth',
        'Date Hired',
    ]

    const csvData = tableData?.map((item) => ({
        'Employee Number': item.employeeNumber,
        Name: item.name,
        Compensation: `$${item.compensation}`,
        Commission: item.commission ? `${item.commission}%` : '',
        'Chemical Applicator License': item.caLicense,
        'Email Address': item.email,
        Address: item.address,
        'Phone Number': item.phoneNumber,
        'Date of Birth': !item.dateOfBirth ? item.dateOfBirth : '',
        'Date Hired': item.dateHired,
    }))

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'employee-list.csv'}
            className={
                'w-[135px] h-[34px] text-zentive-black hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
            onClick={() => {
                setIsOpen && setIsOpen(!isOpen)
            }}
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExportEmployeeCSV
