import { ProfileBusinessType, profileBusinessSchema } from '@/api/business/schema'
import { getBusinessProfile, updateBusinessProfile } from '@/api/profile'
import { Avatar, AvatarComponent, AvatarFallback } from '@/components/Avatar'
import { Button } from '@/components/Button'
import ImageUploader from '@/components/Common/ImageUploader'
import { Form } from '@/components/Forms'
import { USER_STATUS } from '@/constants'
import { useToast } from '@/hooks/useToast'
import {
    currentSubsInfoAtom,
    isOpenModalForChangesAtom,
    manageInfoTabAtom,
    manageSubsTabAtom,
    nextTabAtom,
    profileImgAtom,
} from '@/store/manageSubs'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import DiscardModal from './DiscardModal'
import AddressInput from '@/components/CompleteAddress'
import { AdminBusinessInput } from '@/components/AdminBusinessInput'
import { isAddressSelectedAtom } from '@/store/customer'
import dayjs from 'dayjs'
import { imageUploadErrorAtom } from '@/store/error'
import { UserDataType } from '@/api/auth/schema'

const PersonalInfo = () => {
    const { toast } = useToast()
    const navigate = useNavigate()

    const params = new URLSearchParams(window.location.search)
    const profileId = params.get('profileid')

    const [nextTab] = useAtom(nextTabAtom)
    const [, setSelectedTab] = useAtom(manageInfoTabAtom)
    const userStatus = useAtomValue(manageSubsTabAtom)
    const [currentSubsInfo, setCurrentSubsInfo] = useAtom(currentSubsInfoAtom)
    const [isAddressSelected, setIsAddressSelected] = useAtom(isAddressSelectedAtom)

    const [selectedImageAtom, setSelectedImageAtom] = useAtom(profileImgAtom)
    const [isAvatarChanged, setIsAvatarChanged] = useState(false)

    const [isOpenModalForChanges, setIsOpenModalForChanges] = useAtom(isOpenModalForChangesAtom)

    const firstNameInitial = currentSubsInfo?.firstName ? currentSubsInfo?.firstName.charAt(0) : ''
    const lastNameInitial = currentSubsInfo?.lastName ? currentSubsInfo?.lastName.charAt(0) : ''
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const imageProfileError = useAtomValue(imageUploadErrorAtom)

    const methods = useForm<ProfileBusinessType>({
        mode: 'onChange',
        resolver: zodResolver(profileBusinessSchema),
    })
    const {
        setValue,
        handleSubmit,
        formState: { isValid }, //dirtyFields
        watch,
    } = methods

    const watchFields = watch()

    const handleSuccess = (data: ProfileBusinessType) => {
        setCurrentSubsInfo(data)

        setValue('profileId', data.profileId)
        setValue('addressId', data.addressId)
        setValue('businessId', data.businessId)
        setValue('business.businessName', data.business.businessName)
        setValue('business.businessWebsite', data.business.businessWebsite)
        setValue('business.createdAt', dayjs(data.business.createdAt).format('MMM DD, YYYY'))
        setValue('status', data.status)
        setValue('isPasswordGenerated', data.isPasswordGenerated ?? false)
        setValue('firstName', data.firstName || '')
        setValue('lastName', data.lastName || '')
        setValue('email', data.email)
        setValue('phoneNumber', data.phoneNumber)
        setValue('address.streetAddress', data.address?.streetAddress || '')
        setValue(
            'address.streetTwoAddress',
            data.address.streetTwoAddress === 'undefined' ? '' : data.address.streetTwoAddress,
        )
        setValue('address.city', data.address.city || '')
        setValue('address.state', data.address.state || '')
        setValue('address.zipCode', data.address.zipCode || '')
    }

    const { mutate: getBusinessProfileMu } = useMutation({
        mutationFn: getBusinessProfile,
        onError: (err: AxiosError) => console.log('Error getting business profile:', err.message),
        onSuccess: handleSuccess,
    })

    const { mutate: updateBusinessProfileMu } = useMutation({
        mutationFn: updateBusinessProfile,
        onSuccess: () => {
            setIsOpenModalForChanges(false)
            toast({
                description: 'Business profile updated successfully',
                variant: 'default',
            }),
                navigate(-1)
            setSelectedImageAtom([])
        },
        onError: () => {
            toast({
                description: 'Failed to update business profile',
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: ProfileBusinessType) => {
        const newImageData = selectedImageAtom[0]?.file
        updateBusinessProfileMu({ ...data, profileImage: newImageData })
    }

    const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        navigate(-1)
        setSelectedImageAtom([])
    }

    const isDirty = () => {
        if (isButtonDisabled !== true) return false
        if (isValid === true && isAddressSelected) return false
        if (isAvatarChanged === true) return false
        else return true
    }

    useEffect(() => {
        if (currentSubsInfo) {
            const isEmptyField =
                !watchFields.firstName ||
                !watchFields.lastName ||
                !watchFields.phoneNumber ||
                !watchFields.business.businessName

            const isDisabled =
                isEmptyField ||
                (watchFields.firstName === currentSubsInfo?.firstName &&
                    watchFields.lastName === currentSubsInfo.lastName &&
                    watchFields.business.businessName === currentSubsInfo.business.businessName &&
                    watchFields.phoneNumber === currentSubsInfo.phoneNumber &&
                    watchFields.business.businessWebsite ===
                        currentSubsInfo.business.businessWebsite &&
                    (watchFields.address.streetTwoAddress === ''
                        ? undefined
                        : watchFields.address.streetTwoAddress) ===
                        currentSubsInfo.address.streetTwoAddress)

            setIsButtonDisabled(isDisabled)
        }
    }, [watchFields])

    useEffect(() => {
        setIsAddressSelected(false)
        if (profileId) {
            getBusinessProfileMu(profileId)
        }
    }, [profileId, getBusinessProfileMu])

    useEffect(() => {
        if (selectedImageAtom) {
            setValue('profileImage', selectedImageAtom[0]?.file)
        }
    }, [selectedImageAtom])

    useEffect(() => {
        if (selectedImageAtom && selectedImageAtom?.length > 0) {
            setIsAvatarChanged(true)
        } else {
            setIsAvatarChanged(false)
        }
    }, [selectedImageAtom])

    const handlePreventEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault() // Prevent the 'Enter' key
        }
    }

    return (
        <Form {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handlePreventEnter}>
                <div className='bg-white overflow-scroll'>
                    <section className='flex flex-col items-center justify-center text-center gap-[12px] mt-[35px] w-full'>
                        <Avatar className='w-[74px] h-[74px]'>
                            <AvatarComponent
                                user={(currentSubsInfo as UserDataType) ?? null}
                                selectedImage={selectedImageAtom ?? undefined}
                            />
                            <AvatarFallback>{firstNameInitial + lastNameInitial}</AvatarFallback>
                        </Avatar>
                        {userStatus !== USER_STATUS.DELETED &&
                        userStatus !== USER_STATUS.TERMINATED ? (
                            <div>
                                <ImageUploader
                                    onFilesChange={(data) => setSelectedImageAtom(data)}
                                    maxSize={4.9}
                                    accept='.png,.jpeg,.jpg'
                                    label='Change photo'
                                    labelClassname='text-zentive-blue-dark text-decoration-line: underline'
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </section>
                    <section className='flex flex-col w-full px-[55px] 2xl:px-[26px] gap-[18px] mb-[25px] justify-center items-center'>
                        <AdminBusinessInput />
                        <AddressInput />
                    </section>
                </div>
                {userStatus !== 'TER' && (
                    <div className='flex justify-end bg-[#EBEBEB] rounded-b-[15px]'>
                        <Button
                            onClick={(e) => handleCancelClick(e)}
                            className='w-[95px] h-[43px] mr-[16px] mt-[16px] mb-[16px] text-zentive-green-dark bg-[#F9F9F9] rounded-[5px] ring-2 ring-inset ring-zentive-green-dark font-semibold text-base hover:text-white hover:bg-[#3a671a] '
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={isDirty() && !!imageProfileError}
                            type='submit'
                            className='w-[95px] h-[43px] mr-[45px] mt-[16px] mb-[16px] text-white bg-[#3a671a] rounded-[5px] font-semibold text-base hover:bg-zentive-green-medium'
                        >
                            Update
                        </Button>
                    </div>
                )}
            </form>
            <DiscardModal
                isOpen={isOpenModalForChanges}
                onClose={() => setIsOpenModalForChanges(false)}
                handleDiscard={() => {
                    setIsOpenModalForChanges(false)
                    setSelectedTab(nextTab)
                }}
            />
        </Form>
    )
}

export default PersonalInfo
